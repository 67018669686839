import React from 'react';
import CampaignFormSetupType from './setup-type';

class CampaignFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: props.disabled,
      setupTypes: {
        donation_page: {
          checked: false
        },
        fundraiser_page: {
          checked: false
        },
        commerce_page: {
          checked: false
        }
      }
    }

    this.onChange = this.onChange.bind(this);
  }

  isDisabled(setupTypes) {
    return !setupTypes['donation_page'].checked &&
           !setupTypes['fundraiser_page'].checked &&
           !setupTypes['commerce_page'].checked;
  }

  onChange(event, setupType) {
    const { setupTypes } = this.state;

    if (setupType === 'donation_page' && setupTypes['fundraiser_page'].checked) {
      event.preventDefault();
      return;
    }

    if (setupType === 'fundraiser_page' && event.target.checked) {
      setupTypes['donation_page'].checked = true;
    }

    setupTypes[setupType].checked = event.target.checked;
    const disabled = this.isDisabled(setupTypes);

    this.setState({
      setupTypes: setupTypes,
      disabled: disabled
    });
  }

  render() {
    return (
      <div>
        <CampaignFormSetupType
          setupType="donation_page"
          label="Donation Page"
          checked={this.state.setupTypes.donation_page.checked}
          onChange={this.onChange} />

        <CampaignFormSetupType
          setupType="fundraiser_page"
          label="Peer-to-Peer / Crowdfunding"
          checked={this.state.setupTypes.fundraiser_page.checked}
          onChange={this.onChange} />

        <CampaignFormSetupType
          setupType="commerce_page"
          label="Event"
          checked={this.state.setupTypes.commerce_page.checked}
          onChange={this.onChange} />

        <div className="mt-6 form-item form-item--submit">
          <button
            type="submit"
            className="btn btn-lime btn__large w-full"
            disabled={this.state.disabled}
            data-new-campaign-submit="true">
            Continue
            <svg className="icon-chevron-right ml-1 w-5 h-5">
              <use xlinkHref="#icon-chevron-right" />
            </svg>
          </button>
        </div>

        <div className="text-xs">
          <a href={this.props.cancelUrl} className="text-gray-500">Cancel</a>
        </div>
      </div>
    )
  }
}

export default CampaignFormContainer;
