import React from 'react';

const SuggestionEmail = (props) => {
  const { suggestion } = props;

  return (
    <div data-suggestion-email className="autosuggest-item__email">
      {suggestion.email}
    </div>
  )
}

export default SuggestionEmail;
