import React from 'react';

const Scrollbar = (props) => {
  return (
    <div className="scrollbar">
      <div className="track">
        <div className="thumb"></div>
      </div>
    </div>
  )
}

export default Scrollbar;
