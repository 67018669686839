import React from 'react';
import Option from './option';
import OptionBlank from './option-blank';

const OptionSet = (props) => {
  const { allowBlank, options } = props;

  const optionSet = optionKeys(options).map((label, index) => {
    const value = options[label];
    const key = keyValue(value, index);

    return (
      <Option
        value={value}
        label={label}
        key={key}
      />
    )
  });

  if (allowBlank) {
    optionSet.unshift(<OptionBlank key="none"/>);
  }

  return optionSet;
}

function optionKeys(options) {
  return Object.keys(options);
}

function keyValue(value1, value2) {
  return `${value1}-${value2}`;
}

export default OptionSet;
