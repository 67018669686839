import React from 'react';

class ProgressBar extends React.Component {
  render() {
    const { progress } = this.props;
    const style = { width: `${progress}%` }

    return (
      <div className="progress">
        <div className="progress__bar" style={style}></div>
      </div>
    );
  }
}

export default ProgressBar;
