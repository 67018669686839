const map = {
  'average_total_in_cents_between': 'moneyRange',
  'birthdate_day_in': 'day',
  'birthdate_month_in': 'month',
  'birthdate_year_in': 'year',
  'lifetime_total_in_cents_between': 'moneyRange',
  'newsletter_enabled_eq': 'boolean',
  'year_to_date_total_in_cents_between': 'moneyRange',
  'transaction_in_year': 'year',
  'statement_in_year': 'year',
  'statement_not_in_year': 'year'
}

export default (field) => {
  if (map[field]) {
    return map[field];
  }

  return 'text';
}
