import React from  'react';
import formatNumber from '../../utils/format-number';

class DonorDuplicatesCounts extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const showing = formatNumber(this.props.showing);
    const total = formatNumber(this.props.total);

    return (
      <div className="text-sm mb-2">Showing {showing} of {total}</div>
    );
  }
}

export default DonorDuplicatesCounts;
