import React from  'react';

class DonorDuplicatesFetchButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isDisabled, isVisible, onClick } = this.props;

    if (!isVisible) { return null; }

    return (
      <button
        className="btn btn__outline mb-4"
        disabled={isDisabled}
        onClick={onClick}>
        Load More
      </button>
    );
  }
}

export default DonorDuplicatesFetchButton;
