import React from 'react';

class FormInput extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { onInputRendered } = this.props;

    if (onInputRendered) {
      onInputRendered(this.refs.input);
    }
  }

  render() {
    const {
      className,
      label,
      name,
      onChange,
      placeholder,
      required,
      stateKey,
      type,
      value
    } = this.props;

    return (
      <input
        className={className}
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref='input'
        required={required}
        type={type}
        value={value}
      />
    )
  }
}

export default FormInput;
