import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input']

  initialize () {
    this.defaults = {
      toolbar: 'formatselect | bold italic | link | bullist numlist',
      plugins: 'link,paste',
      menubar: false,
      content_style: '* { font-family: "Arial"; font-size: 14px; line-height: 18px; }',
      block_formats: 'Paragraph=p;Header 2=h2;Header 3=h3',
      paste_as_text: true,
      min_height: 240,
      entity_encoding: 'raw',
      elementpath: false,
      link_assume_external_targets: 'http',

      setup(editor) {
        editor.on('keyUp change', function(ed){
          tinyMCE.triggerSave();
          // $(this.targetElm).valid();
        });
      }
    }
  }

  connect () {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinyMCE.init(config);
  }

  disconnect () {
    tinyMCE.remove(this.inputTarget);
  }
}