import React from 'react';

const DonorSearchFilterDateFieldOptions = (props) => {
  const { options } = props;

  return options.map((option) => {
    const label = option[0];
    const value = option[1];

    return (
      <option value={value} key={value}>
        {label}
      </option>
    );
  });
}

export default DonorSearchFilterDateFieldOptions;
