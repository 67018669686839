const queryString = (paramsArray) => {
  const parameters = [];

  paramsArray.forEach((parameter) => {
    parameters.push(encodeURI(parameter.field + '=' + parameter.value))
  });

  return parameters.join('&');
}

export default queryString;
