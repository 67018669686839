import React from 'react';

const DonorSearchFilterSubmit = (props) => {
  const { onClick } = props;

  return (
    <button
      className="btn btn__neutral btn__small"
      id="filter-submit"
      onClick={onClick}
      type="submit">
      Add Filter
    </button>
  )
}

export default DonorSearchFilterSubmit;
