import React from 'react';

const Option = (props) => {
  const { label, value } = props;

  return (
    <option
      value={value}
    >
      {label}
    </option>
  )
}

export default Option;
