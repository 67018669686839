import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: String }

  scrollTo() {
    // Always clear the hash first so that if
    // the same hash is supplied it will
    // re-scroll to that position.
    const iframe = $('#preview-iframe').get(0);
    iframe.contentWindow.location.hash = '';
    iframe.contentWindow.location.hash = this.idValue;
  }
}
