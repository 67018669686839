import React from 'react';
import FormField from './form-field';
import SelectField from './select-field';

class CountryField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      allowBlank,
      freeForm,
      label,
      name,
      onChange,
      options,
      required,
      stateKey,
      value
    } = this.props;

    if (freeForm) {
      return (
        <FormField
          label={label}
          name={name}
          onChange={onChange}
          required={required}
          stateKey={stateKey}
          type='text'
          value={value}
        />
      )
    }

    return (
      <SelectField
        allowBlank={allowBlank}
        label={label}
        name={name}
        onChange={onChange}
        required={required}
        stateKey={stateKey}
        options={options}
        value={value}
      />
    )
  }
}

export default CountryField;
