import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['layout', 'basic', 'video', 'image', 'form'];
  static values = { layout: String }

  setLayout() {
    const layout = this.layoutValue;

    if (layout) {
      this.showLayout(layout);
    }
  }

  inputLayout(event) {
    const layout = event.target.value;

    this.showLayout(layout);
  }

  resetLayout(event) {
    event.preventDefault();

    $(this.layoutTarget).show();
    $(this.basicTarget).hide();
    $(this.videoTarget).hide();
    $(this.imageTarget).hide();
  }

  showLayout(layout) {
    const target = `${layout}Target`;
    const input = `${layout}Input`;

    $(this.layoutTarget).hide();
    $(this.formTargets).hide();
    $(this[target]).show();
    $(this[input]).prop('checked', true);
  }

  submit(event) {
    event.preventDefault();
    const form = event.target;

    $.ajax({
      url: $(form).attr('action'),
      type: $(form).attr('method'),
      dataType: 'script',
      data: new FormData(form),
      processData: false,
      contentType: false,

      beforeSend: function() {
        $('.loading').show();
      }
    });
  }
}