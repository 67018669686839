import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      label,
      name,
      mask,
      onChange,
      stateKey,
      type,
      value
    } = this.props;

    const inputMask = mask || createNumberMask({
      prefix: '',
      suffix: '',
      includeThousandsSeparator: false,
      allowDecimal: true
    });

    return (
      <MaskedInput
        mask={inputMask}
        name={name}
        onChange={onChange}
        type='text'
        value={value}
      />
    )
  }
}

export default CurrencyInput;
