import React from 'react';
import FormFieldItem from './item';

class FormFieldContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formFields: props.formFields
    }

    this.addFormField = this.addFormField.bind(this);
    this.removeFormField = this.removeFormField.bind(this);
  }

  addFormField(e) {
    e.preventDefault();

    const formField = {
      label: '',
      field_type: 'text',
      required: true,
      options: [],
      '_destroy': false
    }

    const formFields = this.state.formFields;

    formFields.push(formField);

    this.setState({
      formFields: formFields
    });
  }

  removeFormField(index) {
    const formFields = this.state.formFields;

    formFields[index]['_destroy'] = true;

    this.setState({
      formFields: formFields
    });
  }

  notDestroyedFormFields() {
    return this.state.formFields.filter((formField) => !formField._destroy);
  }

  renderContainerLabel() {
    const label = this.props.label;

    if (!label) {
      return;
    }

    if (!this.notDestroyedFormFields().length) {
      return;
    }

    return <label>{label}</label>;
  }

  renderFormFields() {
    const { nestedAttributesName, allowRequired } = this.props;
    const label = this.renderContainerLabel();
    const formFields = this.state.formFields.map((formField, index) => {
      return <FormFieldItem
                formField={formField}
                key={index}
                index={index}
                nestedAttributesName={nestedAttributesName}
                onRemove={this.removeFormField}
                allowRequired={allowRequired} />
    });

    return (
      <div>
        {label}
        {formFields}
      </div>
    );
  }

  render() {
    const formFields = this.renderFormFields();

    return (
      <div className="multiple-fields space-y-4">
        {formFields}

        <button
          className="link--add-field"
          onClick={this.addFormField}
          data-form-fields-add>
          Add Field
        </button>
      </div>
    )
  }
}

export default FormFieldContainer;
