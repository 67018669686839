import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import DonorProfilePlaceholder from './placeholder';
import DonorProfileContactInfoForm from './contact-info/form';
import DonorProfileContactInfoMain from './contact-info/main';

class DonorProfileContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowingForm: false
    }

    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.save = this.save.bind(this);
  }

  showForm() {
    this.setState({ isShowingForm: true });
  }

  hideForm() {
    this.setState({ isShowingForm: false });
  }

  save(donorValues) {
    const { apiKey, donorUpdateUrl, donorAfterUpdateUrl, onUpdate } = this.props;
    const { firstName, lastName, preferredName, address1, address2, city, state, postal, country, newsletterEnabled, email, emailType, alternateEmail, alternateEmailType, phone, phoneType, alternatePhone, alternatePhoneType } = donorValues;

    const donorParams = {
      first_name: firstName,
      last_name: lastName,
      preferred_name: preferredName,
      address_1: address1,
      address_2: address2,
      city: city,
      state: state,
      postal: postal,
      country: country,
      newsletter_enabled: newsletterEnabled,
      email: email,
      email_type: emailType,
      alternate_email: alternateEmail,
      alternate_email_type: alternateEmailType,
      phone: phone,
      phone_type: phoneType,
      alternate_phone: alternatePhone,
      alternate_phone_type: alternatePhoneType
    }

    const params = {
      donor: donorParams,
      api_key: apiKey
    };

    return axios.put(donorUpdateUrl, params)
      .then((response) => {
        return onUpdate();
      })
      .then(() => {
        this.hideForm();
        toast('Success!');

        $.getScript(donorAfterUpdateUrl);
      });
  }

  renderForm() {
    if (!this.state.isShowingForm) {
      return null;
    }

    const { donor, countries, states, provinces } = this.props;

    return (
      <DonorProfileContactInfoForm
        donor={donor}
        onCancel={this.hideForm}
        onUpdate={this.save}
        countries={countries}
        states={states}
        provinces={provinces} />
    )
  }

  render() {
    const { donor, donorLoaded, donorEditUrl, canEditDonor } = this.props;
    let body, edit;

    if (!donorLoaded) {
      body = <DonorProfilePlaceholder />;
    } else {
      body = <DonorProfileContactInfoMain donor={donor} />
    }

    if (canEditDonor) {
      edit = (
        <button onClick={this.showForm} className="block-header__btn" data-test-edit-contact-info>
          <span className="label--small">Edit</span>
        </button>
      )
    }

    const form = this.renderForm();

    return (
      <div className="donor-contact-info card-block" data-donor-contact-info-block>
        <div className="block-header">
          <h2 className="title__h4">Contact Info</h2>
          {edit}
        </div>
        {body}
        {form}
      </div>
    )
  }
}

export default DonorProfileContactInfo;
