import React from 'react';

const DonorProfileTitle = (props) => {
  const { firstName, lastName } = props;

  return (
    <h2 className="donor-info__name title__h4">
      {firstName} {lastName}
    </h2>
  )
}

export default DonorProfileTitle
