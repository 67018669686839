import React from 'react';
import LoadingBar from './loading-bar';
import LoadingText from './loading-text';

const Loading = (props) => {
  const { isLoading, type } = props;

  if (!isLoading) {
    return null;
  }

  if (type === 'bar') {
    return <LoadingBar />
  }

  return <LoadingText />
}

export default Loading;
