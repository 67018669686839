import React from 'react';

const DonorSearchMenuButtonQuantity = (props) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <span className="btn-count" data-menu-button-quantity>
      {value}
    </span>
  )
}

export default DonorSearchMenuButtonQuantity;
