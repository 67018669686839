import React from 'react';

class GiftInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: ''
    }

    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
  }

  onChange(event) {
    const name = event.target.value;
    this.setState({
      name: name
    });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const name = event.target.value;
      this.submitGift(name);
    }
  }

  onClickAdd(event) {
    event.preventDefault();
    const name = this.refs.control.value;
    this.submitGift(name);
  }

  submitGift(name) {
    if (name === '') {
      return;
    }

    const gift = {
      name: name
    }

    this.props.onSubmit(gift);
    this.clearInput();
  }

  componentDidMount() {
    $(this.refs.control).autocomplete({
      serviceUrl: '/api/nonprofits/gifts',
      params: {
        api_key: this.props.apiKey
      },
      triggerSelectOnValidInput: false,
      onSelect: (suggestion) => {
        const name = suggestion.value;
        const id = suggestion.data.id;
        const gift = {
          id: id,
          name: name
        }
        this.props.onSelect(gift);
        this.clearInput();
      }
    });
  }

  clearInput() {
    this.setState({
      name: ''
    });
  }

  render() {
    return (
      <div>
        <input
          data-gift-input
          ref='control'
          value={this.state.name}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          placeholder="Start typing a Gift Designation..."
          type='text'
          className='gift-item--input' />

        <button
          data-gift-button
          onClick={this.onClickAdd}
          className="btn btn__outline btn__gift">
          Add
        </button>
      </div>
    )
  }
}

export default GiftInput;
