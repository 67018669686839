import React from 'react';
import eventValue from '../../../utils/event-value';

const DonorSearchFilterBooleanField = (props) => {
  const { onChange } = props;

  return (
    <div className="form-item form-select" data-filter-input-boolean-container>
      <div className="form-item__container">
        <select
          id="filter-input-boolean"
          onChange={event => onChange(eventValue(event))}
        >
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
    </div>
  )
}

export default DonorSearchFilterBooleanField;
