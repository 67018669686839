import React from 'react';

const DonorProfileContactInfoEmailType = (props) => {
  const { emailType } = props;

  if (!emailType) {
    return null;
  }

  return (
    <span className="caption text--12 color--md-grey">{emailType}</span>
  )
}

export default DonorProfileContactInfoEmailType;
