import React from 'react';
import eventValue from '../../../utils/event-value';

const DonorSearchFilterTextField = (props) => {
  const { onChange, value } = props;

  return (
    <div className="form-item" data-filter-input-container>
      <input
        id="filter-input"
        onChange={event => onChange(eventValue(event))}
        type="text"
        value={value}
      />
    </div>
  )
}

export default DonorSearchFilterTextField;
