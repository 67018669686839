import React from 'react';
import DonorProfileAvatar from './avatar';
import DonorProfileCityState from './city-state';
import DonorProfileGenderAge from './gender-age';
import DonorProfilePlaceholder from './placeholder';
import DonorProfileSocialLinks from './social-links';
import DonorProfileStats from './stats';
import DonorProfileTitle from './title';

const DonorProfileOverview = (props) => {
  const { donor, fullContact, totals, donorLoaded } = props;
  const { gender, ageRange, avatar, facebook, twitter, linkedin, website } = fullContact;

  if (!donorLoaded) {
    return (
      <div className="card-block">
        <DonorProfilePlaceholder
          includeAvatar={true}
          lineCount={3} />
      </div>
    )
  }

  return (
    <div className="card-block">
      <div className="donor-header mb-6">
        <div className="donor-overview" data-donor-overview-block>

          <DonorProfileAvatar
            avatar={avatar}
            firstName={donor.first_name}
            lastName={donor.last_name} />

          <div className="donor-info">
            <DonorProfileTitle
              firstName={donor.first_name}
              lastName={donor.last_name}
            />

            <DonorProfileGenderAge
              gender={gender}
              age={ageRange} />

            <DonorProfileCityState
              city={donor.city}
              state={donor.state} />
          </div>
        </div>

        <DonorProfileSocialLinks
          facebook={facebook}
          twitter={twitter}
          linkedin={linkedin}
          website={website} />
      </div>

      <DonorProfileStats
        yearToDateInCents={totals.year_to_date_in_cents}
        totalInCents={totals.total_in_cents}
        averageInCents={totals.average_in_cents} />
    </div>
  )

}

export default DonorProfileOverview;
