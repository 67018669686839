import normalizedFilters from './normalized-filters';

function keywordParam(keyword, params) {
  return { field: 'keyword', value: keyword };
}

export default (filters, keyword) => {
  const params = [];

  if (keyword) {
    params.push(keywordParam(keyword));
  }

  if (filters) {
    normalizedFilters(filters).forEach((item) => {
      params.push(item);
    })
  }

  return params;
}
