import React from 'react';
import FormItem from './form-item';
import FormInput from './form-input';

class FormField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      className,
      label,
      name,
      onChange,
      onInputRendered,
      required,
      stateKey,
      type,
      value
    } = this.props;

    return (
      <FormItem
        label={label}
        name={name}
      >
        <FormInput
          className={className}
          name={name}
          onChange={onChange.bind(this, stateKey)}
          onInputRendered={onInputRendered}
          required={required}
          type={type}
          value={value}
        />
      </FormItem>
    )
  }
}

export default FormField;
