import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['iframe']

  connect() {
    $(this.iframeTarget).on('load', function() {
      $('.loading').hide();
    });
  }
}
