import React from 'react';

const CurrencyFieldContainer = (props) => {
  return (
    <div className="form-item__container">
      <span className="currency-label">$</span>
      {props.children}
    </div>
  )
}

export default CurrencyFieldContainer;
