import React from 'react';
import DonorProfileContactInfoEmailType from './email-type';

const DonorProfileContactInfoEmail = (props) => {
  const { email, emailType } = props;

  if (!email) {
    return null;
  }

  return (
    <p className="donor-contact__email">
      <a href={`mailto:${email}`}>{email}</a> <DonorProfileContactInfoEmailType emailType={emailType} />
    </p>
  )
}

export default DonorProfileContactInfoEmail;
