import axios from 'axios';

const fetch = (url, params = {}) => {
  return axios.get(url, { params })
    .catch((error) => {
      return error.response;
    });
}

export default fetch;
