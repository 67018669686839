import React from 'react';

const Footer = (props) => {
  return (
    <footer className="flex justify-end">
      <button
        className="btn btn-green"
        onClick={props.onSave}
        disabled={props.disabled}
        data-submit="true">
        Finish Adding Donations

        <svg className="icon-chevron-right ml-1 w-5 h-5">
          <use xlinkHref="#icon-chevron-right" />
        </svg>
      </button>
    </footer>
  )
}

export default Footer;
