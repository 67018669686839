import React from 'react';
import formatCurrency from '../../utils/format-currency';
import formatNumber from '../../utils/format-number';
import moment from 'moment';
import numeral from 'numeral';
import Chart from 'chart.js';
import _ from 'underscore';

class NonprofitDashboardRevenueReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chart: null,
      chartLoading: false,
      revenueLoading: false,
      revenueData: {},
      dateRange: 30
    }

    this.dateChanged = this.dateChanged.bind(this);
  }

  componentDidMount() {
    const { dateRange } = this.state;
    const { dateStart, dateEnd } = this.calculateDates(dateRange);
    this.fetchChartData(dateStart, dateEnd);
    this.fetchRevenueData(dateStart, dateEnd);
  }

  calculateDates(daysAgo) {
    const dateStart = moment().subtract(daysAgo, 'days').format();
    const dateEnd = moment().format();
    return { dateStart, dateEnd };
  }

  dateChanged(event) {
    const dateRange = event.target.value;
    this.setState({ dateRange });

    const { dateStart, dateEnd } = this.calculateDates(dateRange);
    this.fetchChartData(dateStart, dateEnd);
    this.fetchRevenueData(dateStart, dateEnd);
  }

  fetchChartData(dateStart, dateEnd) {
    const { apiKey } = this.props;

    const params = {
      api_key: apiKey,
      date_start: dateStart,
      date_end: dateEnd
    }

    this.setState({ chartLoading: true });

    $.get('/api/nonprofits/revenue/per_day', params)
      .done((data) => {
        this.renderChart(data);
      })
      .fail((error) => {
        this.setState({ isDataError: true });
      })
      .always(() => {
        this.setState({ chartLoading: false });
      });
  }

  fetchRevenueData(dateStart, dateEnd) {
    const { apiKey } = this.props;

    const params = {
      api_key: apiKey,
      date_start: dateStart,
      date_end: dateEnd
    }

    this.setState({ revenueLoading: true });

    $.get('/api/nonprofits/revenue/totals', params)
      .done((data) => {
        this.setState({ revenueData: data });
      })
      .fail((error) => {
        this.setState({ isDataError: true });
      })
      .always(() => {
        this.setState({ revenueLoading: false });
      });
  }

  renderChart(data) {
    let { chart } = this.state;
    const labels = _.keys(data);
    const values = _.values(data);
    const uniqueValues = _.uniq(values);
    const allZeroes = uniqueValues.length === 1 && _.first(uniqueValues) === 0;

    if (chart) {
      chart.data.labels = labels;
      chart.data.datasets[0].data = values;
      chart.options.scales.yAxes[0].ticks.suggestedMax = allZeroes ? 100000 : null;
      chart.options.scales.yAxes[0].ticks.stepSize = allZeroes ? 20000 : null;
      chart.update();
      return;
    }

    Chart.defaults.global.defaultFontFamily = "'Marfa', 'Helvetica', 'arial', 'sans-serif'";
    var ctx = document.getElementById("revenue-chart");
    chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: '#008B49'
        }]
      },
      options: {
        legend: {
          display: false
        },

        tooltips: {
          displayColors: false,
          callbacks: {
            label(tooltipItem, data) {
              const cents = tooltipItem['yLabel'];
              const dollars = cents / 100;
              return numeral(dollars).format('$0,0.00');
            }
          }
        },

        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback(tick, index, ticks) {
                if (tick === 0) { return '$0'; }

                const cents = tick;
                const dollars = cents / 100;

                const greaterThanThousand = dollars > 1000;
                const notEvenThousand = dollars % 1000 !== 0

                if (greaterThanThousand && notEvenThousand) {
                  return numeral(dollars).format('$0.0a');
                } else {
                  return numeral(dollars).format('$0a');
                }
              },
              suggestedMax: allZeroes ? 100000 : null,
              stepSize: allZeroes ? 20000 : null,
              maxTicksLimit: 6
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              autoSkipPadding: 50,
              maxRotation: 0,
              callback(tick, index, ticks) {
                return moment.parseZone(tick).format('MMM D');
              }
            }
          }]
        }
      }
    });

    this.setState({ chart });
  }

  render() {
    let chartLoading;
    if (this.state.chartLoading) {
      chartLoading = <div className="dashboard-loading text-sm text-neutral-600">Loading...</div>;
    }

    let revenueLoading;
    if (this.state.revenueLoading) {
      revenueLoading = <div className="dashboard-loading text-sm text-neutral-600">Loading...</div>;
    }

    const { revenueData } = this.state;

    return (
      <div className="dash-block relative xl:flex pt-20 xl:pt-5 pb-5">

        <div className="xl:basis-3/4 xl:order-last mb-6 xl:mb-0 px-5 xl:pb-5">
          <div className="dashboard-chart relative">
            <h3 className="text-h5 text-center mb-4 md:mb-6">
              Total Revenue
            </h3>

            {chartLoading}
            <canvas id="revenue-chart" height="144"></canvas>
          </div>
        </div>

        <div className="xl:basis-1/4 shrink-0 px-5 border-r border-solid border-neutral-200">
          <div className="dashboard-date-range form-item form-select">
            <div className="form-item__container">
              <select value={this.state.dateRange} data-test-date-range onChange={this.dateChanged}>
                <option value="7">Past week</option>
                <option value="30">Past month</option>
                <option value="365">Past year</option>
              </select>
            </div>
          </div>

          <div className="dashboard-data relative space-y-2 divide-y divide-solid divide-neutral-200">
            {revenueLoading}

            <div className="flex justify-between items-center xl:block">
              <div className="text-sm">
                Total Revenue
              </div>
              <div className="text-stat" data-test-total-revenue>
                {formatCurrency(revenueData.total_revenue_cents)}
              </div>
            </div>

            <div className="flex justify-between items-center xl:block pt-2">
              <div className="text-sm">
                Donations
              </div>
              <div className="text-stat" data-test-total-donation-count>
                {formatNumber(revenueData.total_donation_count)}
              </div>
            </div>

            <div className="flex justify-between items-center xl:block pt-2">
              <div className="text-sm">
                Donors
              </div>
              <div className="text-stat" data-test-total-donor-count>
                {formatNumber(revenueData.total_donor_count)}
              </div>
            </div>

            <div className="flex justify-between items-center xl:block pt-2">
              <div className="text-sm">
                Average Donation Size
              </div>
              <div className="text-stat" data-test-average-donation>
                {formatCurrency(revenueData.average_donation_cents)}
              </div>
            </div>

            <div className="flex justify-between items-center xl:block pt-2">
              <div className="text-sm">
                Registrations
              </div>
              <div className="text-stat" data-test-total-order-count>
                {formatNumber(revenueData.total_order_count)}
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default NonprofitDashboardRevenueReport;
