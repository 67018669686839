import React from 'react';
import axios from "axios";

class DonorImportErrorDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    }

    this.startDownload = this.startDownload.bind(this);
  }

  startDownload() {
    this.showLoading();

    const { apiKey, downloadUrl } = this.props;

    const data = {
      api_key: apiKey
    };

    this.setState({ disabled: true });

    axios({
      url: downloadUrl,
      method: 'post',
      data: data
    })
    .then((response) => {
      const jobId = response.data.job_id;
      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch(() => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  checkJob(jobId) {
    const { apiKey, reportUrl } = this.props;

    axios({
      url: `/api/nonprofits/jobs/${jobId}`,
      method: 'get',
      params: {
        api_key: apiKey
      }
    })
    .then((response) => {
      const { at, total, status } = response.data;
      const fileUrl = response.data.file_url
      this.setState({ progressAt: at, progressTotal: total, status: status })

      if (status === 'failed') {
        this.showError();
        this.setState({ disabled: false });
        return;
      }

      if (status === 'complete') {
        this.showComplete();
        this.setState({ disabled: false });

        return window.location.href = fileUrl;
      }

      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch((error) => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  showLoading() {
    swal({
      title: 'Your download is being prepared...',
      icon: 'info',
      buttons: false
    });
  }

  showComplete() {
    swal({
      title: 'Success! Your download will start automatically.',
      icon: 'success'
    });
  }

  showError() {
    swal({
      title: 'An error occurred and your download could not be completed.',
      icon: 'error'
    });
  }

  render() {
    const { disabled } = this.state;

    return (
      <button disabled={disabled} onClick={this.startDownload} className="btn btn__neutral btn__small">
        Download Errors
      </button>
    )
  }
}

export default DonorImportErrorDownload;
