import React from  'react';
import DonorField from './donor-field';
import CountryField from '../country-field';
import FormField from '../form-field';
import StateField from '../state-field';
import eventValue from '../../utils/event-value';

class DonationFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState(props);

    this.changeField = this.changeField.bind(this);
  }

  changeField(key, event) {
    const state = this.state
    state[key] = eventValue(event);
    this.setState(state);
  }

  countryFreeForm() {
    const { donor } = this.state;
    return donor === null ? false : true;
  }

  fieldName(field) {
    const { modelName } = this.props;
    return `${modelName}[${field}]`;
  }

  stateFreeForm() {
    const { donor } = this.state;
    return donor === null ? false : true;
  }

  defaultState(props) {
    return {
      firstName: props.model.first_name || '',
      lastName: props.model.last_name || '',
      country: props.model.country || '',
      address1: props.model.address_1 || '',
      address2: props.model.address_2 || '',
      city: props.model.city || '',
      state: props.model.state || '',
      postal: props.model.postal || '',
      phone: props.model.phone || '',
      email: props.model.email || '',
      donor: props.donor
    };
  }

  removeDonor() {
    this.setState({
      firstName: '',
      lastName: '',
      country: 'US',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal: '',
      phone: '',
      email: '',
      donor: null
    });
  }

  selectDonor(donor) {
    this.setState({
      firstName: donor.first_name,
      lastName: donor.last_name,
      country: donor.country,
      address1: donor.address_1,
      address2: donor.address_2,
      city: donor.city,
      state: donor.state,
      postal: donor.postal,
      phone: donor.phone,
      email: donor.email,
      donor: donor
    });
  }

  render() {
    const {
      countryAllowBlank,
      countryOptions,
      donor,
      donorUrl,
      provinceLabel,
      provinceOptions,
      stateAllowBlank,
      stateOptions
    } = this.props;

    const {
      firstName,
      lastName,
      country,
      address1,
      address2,
      city,
      state,
      postal,
      phone,
      email
    } = this.state;

    return (
      <>
        <DonorField
          label='Contact'
          name={this.fieldName('donor_id')}
          onRemove={this.removeDonor.bind(this)}
          onSelect={this.selectDonor.bind(this)}
          placeholder='Start typing to find an existing contact'
          searchInputName='donor_search'
          value={donor}
          url={donorUrl}
        />

        <FormField
          label='First Name'
          name={this.fieldName('first_name')}
          onChange={this.changeField}
          required={true}
          stateKey='firstName'
          type='text'
          value={firstName}
        />

        <FormField
          label='Last Name'
          name={this.fieldName('last_name')}
          onChange={this.changeField}
          required={false}
          stateKey='lastName'
          type='text'
          value={lastName}
        />

        <CountryField
          allowBlank={countryAllowBlank}
          freeForm={this.countryFreeForm()}
          label='Country'
          name={this.fieldName('country')}
          onChange={this.changeField}
          options={countryOptions}
          stateKey='country'
          value={country}
        />

        <FormField
          label='Address 1'
          name={this.fieldName('address_1')}
          onChange={this.changeField}
          stateKey='address1'
          type='text'
          value={address1}
        />

        <FormField
          label='Address 2'
          name={this.fieldName('address_2')}
          onChange={this.changeField}
          stateKey='address2'
          type='text'
          value={address2}
        />

        <FormField
          label='City'
          name={this.fieldName('city')}
          onChange={this.changeField}
          stateKey='city'
          type='text'
          value={city}
        />

        <StateField
          allowBlank={stateAllowBlank}
          country={country}
          freeForm={this.stateFreeForm()}
          freeFormLabel='State'
          name={this.fieldName('state')}
          onChange={this.changeField}
          provinceLabel='Province'
          provinceOptions={provinceOptions}
          required={false}
          stateKey='state'
          stateLabel='State'
          stateOptions={stateOptions}
          value={state}
        />

        <FormField
          label='Postal'
          name={this.fieldName('postal')}
          onChange={this.changeField}
          stateKey='postal'
          type='text'
          value={postal}
        />

        <FormField
          label='Phone'
          name={this.fieldName('phone')}
          onChange={this.changeField}
          stateKey='phone'
          type='text'
          value={phone}
        />

        <FormField
          label='Email'
          name={this.fieldName('email')}
          onChange={this.changeField}
          stateKey='email'
          type='text'
          value={email}
        />
      </>
    )
  }
}

export default DonationFormContainer;
