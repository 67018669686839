import React from 'react';
import formatCurrency from '../../utils/format-currency';

class NonprofitDashboardCampaignProgressBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const goal = this.props.goal;
    const amount = this.props.amount;
    const percent = Math.min(goal === 0 ? 0 : (amount / goal) * 100, 100);
    const style = { width: `${percent}%` };

    return (
      <div className="md:text-right">
        <div>
          {formatCurrency(amount)}
        </div>
        <div className="md:w-32 h-1 bg-neutral-300 overflow-hidden rounded">
          <div className="dashboard-campaigns__progress-bar h-1" style={style}></div>
        </div>
      </div>
    );
  }
}

export default NonprofitDashboardCampaignProgressBar;
