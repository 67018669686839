import React from  'react';
import moment from 'moment';

class DonorDuplicatesRecord extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { record } = this.props;
    const date = moment.parseZone(record.created_at).format('MM/DD/YYYY');

    return (
      <div className="duplicate-set__record">
        <div className="duplicate-set__col title__h4">
          <a href={record.profile_url}>
            {record.first_name} {record.last_name}
          </a>
        </div>

        <div className="duplicate-set__col text-sm">
          {date}
        </div>

        <div className="duplicate-set__col text-sm">
          {record.email}
        </div>
      </div>
    );
  }
}

export default DonorDuplicatesRecord;
