import React from 'react';
import DonorSearchFilterBooleanField from './boolean-field';
import DonorSearchFilterDateField from './date-field';
import DonorSearchFilterTextField from './text-field';
import DonorSearchFilterMoneyRangeField from './money-range-field';

const DonorSearchFilterValueFields = (props) => {
  const {
    dayOptions,
    monthOptions,
    onChange,
    type,
    value,
    yearOptions
  } = props;

  if (type === 'text') {
    return (
      <DonorSearchFilterTextField
        onChange={onChange}
        value={value}
      />
    )
  }

  if (type === 'boolean') {
    return (
      <DonorSearchFilterBooleanField
        onChange={onChange}
      />
    )
  }

  if (type === 'day') {
    return (
      <DonorSearchFilterDateField
        onChange={onChange}
        options={dayOptions}
        type='day'
      />
    )
  }

  if (type === 'month') {
    return (
      <DonorSearchFilterDateField
        onChange={onChange}
        options={monthOptions}
        type='month'
      />
    )
  }

  if (type === 'year') {
    return (
      <DonorSearchFilterDateField
        onChange={onChange}
        options={yearOptions}
        type='year'
      />
    )
  }

  if (type === 'moneyRange') {
    return (
      <DonorSearchFilterMoneyRangeField
        onChange={onChange}
      />
    )
  }
}

export default DonorSearchFilterValueFields;
