import React from 'react';

const DonorSearchTagRemove = (props) => {
  const { field, onClick } = props;

  return (
    <button
      className="d-search-remove d-search-delete remove"
      data-filter-remove={field}
      onClick={onClick.bind(this, field)}>
      Remove
    </button>
  )
}

export default DonorSearchTagRemove;
