import React from 'react';


const DonorSearchFilterMoneyRangeLabel = (props) => {
  const { max, min } = props;

  return (
    <div className="input-range__value">
      {min} - {max}
    </div>
  )
}

export default DonorSearchFilterMoneyRangeLabel;
