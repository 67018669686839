import React from 'react';
import DonorSearchMenuButtonQuantity from './menu-button-quantity';

const DonorSearchMenuButton = (props) => {
  const {
    onClick,
    quantity,
    text,
    triggerName
  } = props;

  return (
    <div
      className="btn btn-filters btn__outline btn__small"
      data-filter-menu-trigger={triggerName}
      onClick={onClick}
    >
      <DonorSearchMenuButtonQuantity value={quantity} />

      <span className="btn-filters__label">{text}</span>

      <svg className="icon-chevron-down">
        <use xlinkHref="#icon-chevron-down" />
      </svg>
    </div>
  )
}

export default DonorSearchMenuButton;
