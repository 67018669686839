import React from 'react';

class CampaignFormSetupType extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event, this.props.setupType);
  }

  render() {
    const id = `type_${this.props.setupType}`;

    return (
      <div className="form-item--path-option relative mb-4">
        <label htmlFor={id} className="path-option">
          <input
            type="checkbox"
            name="setup_types[]"
            value={this.props.setupType}
            id={id}
            checked={this.props.checked}
            onChange={this.onChange} />

          <span className="path-option--label">
            {this.props.label}
          </span>
        </label>
      </div>
    )
  }
}

export default CampaignFormSetupType;
