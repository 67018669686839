import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import FieldMappings from './field-mappings/fields';
import Footer from './footer';

class DonationImportImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      progressTotal: 0,
      progressAt: 0,
      disabled: true,
      status: 'not_started'
    }

    this.startImport = this.startImport.bind(this);
    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
  }

  save() {
    const { apiKey, updateUrl } = this.props;
    const fieldMap = this.generateFieldMap();
    const data = {
      api_key: apiKey,
      nonprofit_donation_import: {
        field_map: fieldMap
      }
    }

    axios({
      url: updateUrl,
      method: 'put',
      data: data
    })
    .then((response) => {
      this.startImport();
    })
    .catch(() => {
      this.showError();
    });
  }

  startImport() {
    this.showLoading();

    const { apiKey, nonprofitDonationImportId, importUrl } = this.props;
    const data = {
      nonprofit_donation_import_id: nonprofitDonationImportId,
      api_key: apiKey
    };

    this.setState({ disabled: true });

    axios({
      url: importUrl,
      method: 'post',
      data: data
    })
    .then((response) => {
      const jobId = response.data.job_id;
      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch(() => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  checkJob(jobId) {
    const { apiKey, reportUrl } = this.props;

    axios({
      url: `/api/nonprofits/jobs/${jobId}`,
      method: 'get',
      params: {
        api_key: apiKey
      }
    })
    .then((response) => {
      const { at, total, status } = response.data;
      this.setState({ progressAt: at, progressTotal: total, status: status })

      if (status === 'failed') {
        this.showError();
        this.setState({ disabled: false });
        return;
      }

      if (status === 'complete') {
        this.showComplete();
        return window.location.href = reportUrl;
      }

      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch((error) => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  generateFieldMap() {
    const fieldMap = [];
    const fields = document.querySelectorAll('[name="nonprofit_donation_import[field_map][]"]');

    fields.forEach((field, index) => {
      fieldMap[index] = field.value;
    });

    return fieldMap;
  }

  validate() {
    this.setState({ disabled: !this.validMappings() });
  }

  validMappings() {
    const fieldMap = this.generateFieldMap();
    const requiredFields = ['first_name', 'total', 'transacted_at'];
    return requiredFields.every(elem => fieldMap.indexOf(elem) > -1);
  }

  showLoading() {
    swal({
      title: 'Your import is working...',
      icon: 'info',
      buttons: false
    });
  }

  showComplete() {
    swal({
      title: 'Success! Redirecting to status report...',
      icon: 'success'
    });
  }

  showError() {
    swal({
      title: 'An error occurred and your import could not be completed.',
      icon: 'error'
    });
  }

  render() {
    const { disabled, status } = this.state;
    const { sampleRowSets } = this.props;

    return (
      <>
        <FieldMappings
          sampleRowSets={sampleRowSets}
          onChange={this.validate} />

        <Footer
          onSave={this.save}
          disabled={disabled} />
      </>
    )
  }
}

export default DonationImportImport;
