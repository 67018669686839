import React from 'react';
import NonprofitDashboardCampaignProgressBar from './campaign-progress-bar';

class NonprofitDashboardCampaignList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      isLoading: false,
      isDataError: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const params = {
      api_key: this.props.apiKey
    }

    $.get('/api/nonprofits/campaigns/recent', params)
      .done((data) => {
        this.setState({ campaigns: data });
      })
      .fail((error) => {
        this.setState({ isDataError: true });
      })
      .always(() => {
        this.setState({ isLoading: false });
      });
  }

  renderCampaigns() {
    let { campaigns } = this.state;

    if (campaigns.length === 0) {
      return <p className="dashboard__no-activity">No recent campaigns.</p>;
    }

    return this.state.campaigns.map((campaign) => {
      const progressBar = <NonprofitDashboardCampaignProgressBar
        goal={campaign.goal_in_cents}
        amount={campaign.total_in_cents}
      />

      return (
        <div className="relative md:flex items-start justify-between gap-2 pt-4 first:pt-0" key={campaign.id}>
          <h4 className="font-medium leading-5 mb-2 md:mb-0">
            {campaign.name}
          </h4>
          {progressBar}
        </div>
      );
    });
  }

  renderBody() {
    if (this.state.isLoading) {
      return <div className="dashboard-loading text-sm text-neutral-600">Loading...</div>;
    }

    if (this.state.isDataError) {
      return <p className="dashboard__error">This data cannot be shown at this time.</p>;
    }

    return this.renderCampaigns();
  }

  render() {
    const body = this.renderBody();

    return (
      <div className="dash-recent-campaigns dash-block h-full p-5">
        <header className="mb-5">
          <h3 className="text-h5">
            Recent Campaigns
          </h3>
        </header>
        <div className="space-y-4 divide-y divide-neutral-200 divide-solid">
          {body}
        </div>
      </div>
    );
  }
}

export default NonprofitDashboardCampaignList;
