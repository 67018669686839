import React from  'react';
import axios from 'axios';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Counts from './counts';
import DuplicateSets from './sets';
import FetchButton from './fetch-button';
import Loading from '../loading';

class DonorDuplicatesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialData: false,
      isDisabledControls: false,
      isDisabledFetch: false,
      isLoading: false,
      isMoreToFetch: true,
      records: [],
      total: 0
    }

    this.clickLoadMore = this.clickLoadMore.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
    this.onIgnore = this.onIgnore.bind(this);
    this.onMerge = this.onMerge.bind(this);
  }

  componentDidMount() {
    this.fetchResults()
      .then(() => {
        this.setState({ initialData: true });
      })
  }

  checkForLoadMore() {
    if (this.state.records.length == 2) {
      this.fetchResults();
    }
  }

  clickLoadMore() {
    return this.fetchResults();
  }

  disableControls() {
    this.setState({ isDisabledControls: true });
  }

  disableFetch() {
    this.setState({ isDisabledFetch: true });
  }

  enableControls() {
    this.setState({ isDisabledControls: false });
  }

  enableFetch() {
    this.setState({ isDisabledFetch: false });
  }

  fetchResults() {
    this.startLoading();
    this.disableFetch();

    return axios
      .get(this.props.url, {
        params: {
          offset: this.state.records.length
        }
      })
      .then((response) => {
        const { records } = this.state;
        const { duplicate_sets } = response.data;
        const { total_count } = response.data.meta;

        records.push(...duplicate_sets);

        const isMoreToFetch = total_count > records.length;

        return this.setState({
          isMoreToFetch: isMoreToFetch,
          records: records,
          total: total_count
        });
      })
      .then(() => {
        this.stopLoading();
        this.enableFetch();
      });
  }

  onIgnore(set) {
    this.setupAnimation(set);
    this.startLoading();
    this.disableControls();
    this.disableFetch();

    return axios
      .put(set.ignore_url)
      .then((response) => {
        const records = this.removeRecord(set.id);
        const total = response.data.meta.total_count;

        return this.setState({
          records: records,
          total: total
        });
      })
      .then(() => {
        toast('Ignore Successful');
        this.stopLoading();
        this.enableControls();
        this.enableFetch();
        this.checkForLoadMore();
      });
  }

  onMerge(set) {
    this.setupAnimation(set);
    this.startLoading();
    this.disableControls();
    this.disableFetch();

    return axios
      .put(set.merge_url)
      .then((response) => {
        const records = this.removeRecord(set.id);
        const total = response.data.meta.total_count;

        return this.setState({
          records: records,
          total: total
        });
      })
      .then(() => {
        toast('Merge Successful');
        this.stopLoading();
        this.enableControls();
        this.enableFetch();
        this.checkForLoadMore();
      });
  }

  removeRecord(id) {
    return this.state.records.filter((item) => {
      return id !== item.id;
    });
  }

  setupAnimation(set) {
    const $set = $(`[data-set="${set.id}"]`);
    const height = $set.outerHeight();
    $set.css('max-height', height);
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  render() {
    let output;

    if (this.state.initialData === false) {
      output = (
        <div className="card-block">
          <p className="text--15">Loading...</p>
        </div>
      )
    } else if (this.state.total === 0) {
      output = (
        <div className="card-block">
          <p className="text--15">No duplicates found.</p>
        </div>
      )
    } else {
      output = (
        <>
          <Counts
            showing={this.state.records.length}
            total={this.state.total}
          />

          <DuplicateSets
            isDisabled={this.state.isDisabledControls}
            onIgnore={this.onIgnore}
            onMerge={this.onMerge}
            sets={this.state.records}
          />

          <div className="pt-6 text-center">
            <FetchButton
              isDisabled={this.state.isDisabledFetch}
              isVisible={this.state.isMoreToFetch}
              onClick={this.fetchResults}
              total={this.state.total}
            />

            <Counts
              showing={this.state.records.length}
              total={this.state.total}
            />
          </div>
        </>
      )
    }

    return (
      <>
        {output}

        <Loading
          isLoading={this.state.isLoading}
          type='bar'
        />

        <ToastContainer
          className="toast-container"
          toastClassName="dark-toast"
          position={toast.POSITION.BOTTOM_CENTER}
          transition={Slide}
          hideProgressBar={true}
          autoClose={2500}
        />
      </>
    )
  }
}

export default DonorDuplicatesContainer;
