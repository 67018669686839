import React from "react";
import axios from "axios";
import DonorProfileContactInfo from './contact-info';
import DonorProfileCustomFields from './custom-fields';
import DonorProfileNoteList from './note-list';
import DonorProfileOverview from './overview';
import DonorProfilePersonalInfo from './personal-info';
import DonorProfileRelationships from './relationships';
import DonorProfileTags from './tags';
import DonorProfileTransactionList from './transaction-list';
import Loading from '../loading';
import { ToastContainer, toast, Slide } from 'react-toastify';
import swalError from '../../utils/swal-error';
import fetch from '../../utils/fetch';

import 'react-toastify/dist/ReactToastify.css';

class DonorProfileContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      donor: {},
      totals: {},
      fullContact: {},
      donorLoaded: false,
      isLoading: true
    }

    this.refreshDonor = this.refreshDonor.bind(this);
    this.deleteDonor = this.deleteDonor.bind(this);
  }

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll() {
    this.setState({ isLoading: true });

    const { donor, fullContactUrl, donorUrl, totalsUrl } = this.props;
    const { email } = donor;

    const fetchers = [
      this.fetch('donor', donorUrl),
      this.fetch('totals', totalsUrl),
    ];

    if (email) {
      const fullContactFetch = this.fetch('fullContact', fullContactUrl, { email });
      fetchers.push(fullContactFetch);
    }

    axios.all(fetchers)
      .then(() => {
        this.setState({ isLoading: false, donorLoaded: true });
      })
      .catch((errors) => {
        swalError(errors);
      });
  }

  fetch(stateKey, url, params = {}) {
    return fetch(url, params).then((response) => {
      const { state } = this;
      state[stateKey] = response.data;
      this.setState(state);
    });
  }

  refreshDonor() {
    const { donorUrl } = this.props;
    return this.fetch('donor', donorUrl);
  }

  deleteDonor() {
    const { donorDeleteUrl } = this.props;

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ['Cancel', 'Delete'],
      dangerMode: true
    }).then((confirmed) => {
      if (!confirmed) { return false; }

      window.location.href = donorDeleteUrl;
    });
  }

  renderPersonalInfo() {
    const { apiKey, donorUpdateUrl, canEditDonor, donorsSuggestionsUrl } = this.props;
    const { donorLoaded, donor } = this.state;

    return (
      <DonorProfilePersonalInfo
        apiKey={apiKey}
        donor={donor}
        donorLoaded={donorLoaded}
        donorUpdateUrl={donorUpdateUrl}
        canEditDonor={canEditDonor}
        onUpdate={this.refreshDonor} />
    )
  }

  renderRelationsihps() {
    const { apiKey, donorUpdateUrl, canEditDonor, donorsSuggestionsUrl, donorRelationshipsCreateBulkUrl } = this.props;
    const { donorLoaded, donor } = this.state;

    return (
      <DonorProfileRelationships
        relationships={donor.donor_relationships}
        apiKey={apiKey}
        donor={donor}
        donorLoaded={donorLoaded}
        canEditDonor={canEditDonor}
        onUpdate={this.refreshDonor}
        donorsSuggestionsUrl={donorsSuggestionsUrl}
        donorRelationshipsCreateBulkUrl={donorRelationshipsCreateBulkUrl}
      />
    )
  }

  renderTransactions() {
    const { donor, apiKey, donationAddUrl, transactionsPerPage, canAddDonation } = this.props;

    return (
      <DonorProfileTransactionList
        donorId={donor.id}
        apiKey={apiKey}
        donationAddUrl={donationAddUrl}
        transactionsPerPage={transactionsPerPage}
        canAddDonation={canAddDonation} />
    )
  }

  renderNotes() {
    const { donor, apiKey, notesPerPage, notesUrl, notesAddUrl, canEditNote, canAddNote } = this.props;

    return (
      <DonorProfileNoteList
        donorId={donor.id}
        apiKey={apiKey}
        notesUrl={notesUrl}
        notesAddUrl={notesAddUrl}
        notesPerPage={notesPerPage}
        canEditNote={canEditNote}
        canAddNote={canAddNote} />
    )
  }

  renderTags() {
    const { donor, apiKey, tagsUrl, tagsSuggestionsUrl, canEditDonor } = this.props;
    const { donorLoaded } = this.state;

    return (
      <DonorProfileTags
        donorId={donor.id}
        apiKey={apiKey}
        tagsUrl={tagsUrl}
        tagsSuggestionsUrl={tagsSuggestionsUrl}
        donorLoaded={donorLoaded}
        canEditDonor={canEditDonor} />
    )
  }

  renderCustomFields() {
    const { apiKey, donorFormFieldsCreateBulkUrl, donorFormFieldsUpdateBulkUrl, canEditDonor } = this.props;
    const { donorLoaded, donor } = this.state;

    return (
      <DonorProfileCustomFields
        apiKey={apiKey}
        donor={donor}
        donorLoaded={donorLoaded}
        donorFormFieldsCreateBulkUrl={donorFormFieldsCreateBulkUrl}
        donorFormFieldsUpdateBulkUrl={donorFormFieldsUpdateBulkUrl}
        canEditDonor={canEditDonor}
        onUpdate={this.refreshDonor} />
    )
  }

  renderHeader() {
    const { donorsUrl, showStatementButton } = this.props;
    const { donor } = this.state;
    const backUrlCookie = Cookies.get('fagc_donors_search_href');
    const backUrl = backUrlCookie ? backUrlCookie : donorsUrl;

    let statementButton;
    if (showStatementButton) {
      statementButton = (
        <div className="content-header__btns">
          <div className="ml-20 relative">
            <button
              className="btn btn__outline btn__small"
              data-is-open-trigger="receipt-controls">

              <div>Send Statement</div>

              <svg className="icon-chevron-down ui-icon--small ml-1">
                <use xlinkHref="#icon-chevron-down" />
              </svg>
            </button>

            <div className="drop-down align-right" data-is-open-menu="receipt-controls">
              <div className="viewport">
                <div className="mb-1">
                  <button
                    type="button"
                    className="btn--plain text-sm"
                    data-modal-trigger="#statement-email-modal">
                    Email
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn--plain text-sm"
                    data-modal-trigger="#statement-download-modal">
                    Download Statement
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="content-header" data-donor-header>
        <a href={backUrl} className="back-link">
          <svg className="icon-arrow-left">
            <use xlinkHref="#icon-arrow-left"></use>
          </svg>
          <span className="label--small">Contacts</span>
        </a>
        <h1 className="page-title title__h1 text-green-dk" data-donor-name>{donor.first_name} {donor.last_name}</h1>

        {statementButton}
      </div>
    )
  }

  renderDeleteButton() {
    const { canEditDonor } = this.props;

    if (!canEditDonor) {
      return '';
    }

    const deleteButton = (
      <button
        onClick={this.deleteDonor}
        type="button"
        className="btn btn--destructive btn__small"
        data-delete-donor="true">
        Delete Contact
      </button>
    );

    return deleteButton;
  }

  render() {
    const {
      donor,
      donorLoaded,
      fullContact,
      isLoading,
      totals
    } = this.state;

    const {
      apiKey,
      canEditDonor,
      countries,
      donorUpdateUrl,
      donorAfterUpdateUrl,
      provinces,
      states
    } = this.props;

    const personalInfo = this.renderPersonalInfo();
    const relationships = this.renderRelationsihps();
    const transactions = this.renderTransactions();
    const notes = this.renderNotes();
    const tags = this.renderTags();
    const customFields = this.renderCustomFields();
    const header = this.renderHeader();
    const deleteButton = this.renderDeleteButton();

    return (
      <div data-donor-loaded={donorLoaded}>
        {header}

        <div className="grid md:grid-cols-3 gap-8">
          <Loading isLoading={isLoading} type='bar' />

          <div className="col-span-2">
            <DonorProfileOverview
              donor={donor}
              donorLoaded={donorLoaded}
              fullContact={fullContact}
              totals={totals}
            />

            {transactions}
            {notes}
          </div>

          <div className="col-span-1">
            {tags}

            <DonorProfileContactInfo
              apiKey={apiKey}
              canEditDonor={canEditDonor}
              countries={countries}
              donor={donor}
              donorLoaded={donorLoaded}
              donorUpdateUrl={donorUpdateUrl}
              donorAfterUpdateUrl={donorAfterUpdateUrl}
              onUpdate={this.refreshDonor}
              provinces={provinces}
              states={states} />

            {personalInfo}
            {relationships}
            {customFields}
          </div>

          <ToastContainer
            className="toast-container"
            toastClassName="dark-toast"
            position={toast.POSITION.BOTTOM_CENTER}
            transition={Slide}
            hideProgressBar={true}
            autoClose={2000} />
        </div>

        <footer className="page-footer">
          {deleteButton}
        </footer>
      </div>
    )
  }
}

export default DonorProfileContainer;
