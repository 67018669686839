import React from 'react';
import FormField from './form-field';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

class DateField extends React.Component {
  constructor(props) {
    super(props);
  }

  inputRendered(el) {
    $(el).datepicker({
      dateFormat: "mm/dd/yy"
    });
  }

  formattedDate(value) {
    const date = value || new Date();
    return moment(date).format('MM/DD/YYYY');
  }

  render() {
    const {
      className,
      label,
      name,
      onChange,
      stateKey,
      type,
      value
    } = this.props;

    return (
      <FormField
        label={label}
        name={name}
        onChange={onChange}
        onInputRendered={this.inputRendered}
        stateKey={stateKey}
        type='text'
        value={this.formattedDate(value)}
      />
    )
  }
}

export default DateField;
