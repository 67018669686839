import filterValueJoin from './filter-value-join';
import formatCurrency from '../format-currency';

export default (value) => {
  const values = value.split('-');
  const min = values[0];
  const max = values[1];

  const formattedMin = formatCurrency(parseInt(min), '$0,0');
  let formattedMax = formatCurrency(parseInt(max), '$0,0');

  if (max.includes('>')) {
    formattedMax = `${formattedMax}+`;
  }

  const formatted = [formattedMin, formattedMax];

  return filterValueJoin(formatted, '-');
}
