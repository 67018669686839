import React from 'react';

const DonorProfileContactInfoCityStatePostal = (props) => {
  const { city, className, postal, state } = props;

  if (!city && !postal && !state) {
    return null;
  }

  const cityState = [city, state].filter(Boolean).join(', ');

  return (
    <p className={className}>
      {cityState} {postal}
    </p>
  )
}

export default DonorProfileContactInfoCityStatePostal;
