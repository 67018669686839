const fields = {
  'donations_count_eq': 0,
  'donations_count_gt': 0,
  'orders_count_eq': 0,
  'orders_count_gt': 0,
  'year_to_date_total_in_cents_gteq': 50000,
  'average_total_in_cents_gteq': 50000
}

export function isNumberField(field) {
  return Object.keys(fields).includes(field);
}

export function numberFieldValue(field) {
  return fields[field];
}
