const map = {
  'newsletter_enabled_eq': 'true',
  'birthdate_day_in': '01',
  'birthdate_month_in': '01',
  'birthdate_year_in': new Date().getFullYear(),
  'transaction_in_year': new Date().getFullYear(),
  'statement_in_year': new Date().getFullYear(),
  'statement_not_in_year': new Date().getFullYear()
}

export default (field) => {
  if (map[field]) {
    return map[field];
  }

  return '';
}
