import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['oneColumn', 'twoColumn', 'video', 'form'];
  static values = { layout: String }

  setLayout() {
    const layout = this.layoutValue;

    if (layout) {
      this.showLayout(layout);
    }
  }

  inputLayout(event) {
    let layout = event.target.value;

    this.showLayout(layout);
  }

  showLayout(layout) {
    layout = this.formatLayoutName(layout);
    const target = `${layout}Target`;

    $(this.formTargets).hide();
    $(this[target]).show();
  }

  formatLayoutName(layout) {
    // Since we are using `targets`, we
    // must convert the form value to camelCase
    if (layout === 'one_column') {
      layout = 'oneColumn';
    } else if (layout === 'two_column') {
      layout = 'twoColumn';
    }

    return layout;
  }

  submit(event) {
    event.preventDefault();
    const form = event.target;

    $.ajax({
      url: $(form).attr('action'),
      type: $(form).attr('method'),
      dataType: 'script',
      data: new FormData(form),
      processData: false,
      contentType: false,

      beforeSend: function() {
        $('.loading').show();
      }
    });
  }
}