import React from 'react';

const DonorSearchKeywordButton = (props) => {
  return (
    <button
      className="d-search-btn"
      data-search-button
      type="submit"
    >
      <svg className="icon-search ui-icon--small">
        <use xlinkHref="#icon-search"></use>
      </svg>
    </button>
  )
}

export default DonorSearchKeywordButton;
