import React from  'react';
import DonorSearchMenuButton from './menu-button';
import DonorSearchQuickFiltersDropDown from './quick-filters-drop-down';
import objectCount from '../../utils/object-count';

class DonorSearchQuickFiltersMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    }

    this.clickDocument = this.clickDocument.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.clickDocument, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickDocument, false);
  }

  clickDocument(e) {
    const { target } = e;
    const { node } = this.refs;
    const { isVisible } = this.state;

    if (!node.contains(target) && isVisible) {
      this.setState({ isVisible: false });
    }
  }

  filterCount() {
    const { filters } = this.props;

    return objectCount(filters);
  }

  toggleMenu() {
    const { isVisible } = this.state;

    this.setState({ isVisible: !isVisible });
  }

  render() {
    const { isVisible } = this.state;

    const {
      filters,
      onDisable,
      onEnable
    } = this.props;

    const menu = !isVisible ? null : (
      <DonorSearchQuickFiltersDropDown
        filters={filters}
        onDisable={onDisable}
        onEnable={onEnable}
      />
    );

    return (
      <form
        ref="node"
        data-donors-filters-form
        data-quick-filters-menu
        className="d-filter-form"
      >
        <div className="d-quick-filters">
          <DonorSearchMenuButton
            onClick={this.toggleMenu}
            quantity={this.filterCount()}
            text="Quick Filters"
            triggerName="quick-filters"
          />

          {menu}
        </div>
      </form>
    )
  }
}

export default DonorSearchQuickFiltersMenu;
