import React from 'react';
import DonorSearchFieldFiltersMoneyRangeLabel from './money-range-label';
import InputRange from 'react-input-range';
import formatCurrency from '../../../utils/format-currency';
import formatMinMax from '../../../utils/format-min-max';
import 'react-input-range/lib/css/index.css';

const labelMap = {
  0: 0,
  1: 2500,
  2: 5000,
  3: 7500,
  4: 10000,
  5: 25000,
  6: 50000,
  7: 100000,
  8: 250000,
  9: 500000,
  10: 1000000,
  11: 1000000
}

class DonorSearchFilterMoneyRangeField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 2, max: 4 }
    }

    this.change = this.change.bind(this);
    this.maxRange = this.maxRange();
    this.minRange = this.minRange();
  }

  componentDidMount() {
    const { value } = this.state;
    this.notifyChange(value);
  }

  change(value) {
    this.setState({ value });
    this.notifyChange(value);
  }

  formatLabel(value) {
    const mapped = labelMap[value];

    let formatted = formatCurrency(mapped, '$0,0');

    if (this.isMaxValue(value)) {
      formatted = `${formatted}+`;
    }

    return formatted;
  }

  isMaxValue(value) {
    return value === this.maxRange;
  }

  maxRange() {
    return Object.keys(labelMap).length - 1;
  }

  minRange() {
    return 0;
  }

  notifyChange(value) {
    const { onChange } = this.props;
    const { min, max } = value;

    const mappedMin = labelMap[min];
    const mappedMax = labelMap[max];

    let formattedMin = mappedMin;
    let formattedMax = mappedMax;

    if (this.isMaxValue(max)) {
      formattedMax = `${formattedMax}>`;
    }

    const formatted = formatMinMax(formattedMin, formattedMax);

    onChange(formatted);
  }

  render() {
    const { value } = this.state;

    return (
      <div className="form-item" data-filter-input-container>
        <InputRange
          maxValue={this.maxRange}
          minValue={this.minRange}
          onChange={this.change}
          step={1}
          value={value}
        />

        <DonorSearchFieldFiltersMoneyRangeLabel
          max={this.formatLabel(value.max)}
          min={this.formatLabel(value.min)}
        />
      </div>
    )
  }
}

export default DonorSearchFilterMoneyRangeField;
