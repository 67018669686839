import React from 'react';
import Option from './option';

const OptionBlank = (props) => {
  return (
    <Option
      value=""
      label="- None -"
    />
  )
}

export default OptionBlank;
