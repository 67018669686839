import React from  'react';
import DonorSearchButton from './keyword/button';
import DonorSearchClear from './keyword/clear';
import FormInput from '../form-input';

class DonorSearchKeyword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.keyword || ''
    }

    this.changeField = this.changeField.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(props) {
    // Needed so that one search form
    // can update the other
    this.setState({ value: props.keyword || '' });
  }

  changeField(event) {
    const { value } = event.target;
    this.setState({ value });
  }

  submit(event) {
    const { onSubmit } = this.props;
    const { value } = this.state;

    event.preventDefault();

    onSubmit(value);
  }

  render() {
    const { url } = this.props;
    const { value } = this.state;

    return (
      <form
        className="d-keyword-form"
        data-donors-search-form
        onSubmit={this.submit}
      >
        <FormInput
          name='keyword'
          onChange={this.changeField}
          placeholder="Search"
          required={false}
          type='text'
          value={value}
        />

        <DonorSearchButton />

        <DonorSearchClear
          keyword={value}
          url={url}
        />
      </form>
    )
  }
}

export default DonorSearchKeyword;
