import React from  'react';

import DataDownload from '../data-download';
import DonorSearchFieldFiltersMenu from './field-filters-menu';
import DonorSearchKeyword from './keyword';
import DonorSearchQuickFiltersMenu from './quick-filters-menu';
import DonorSearchTags from './tags';
import Loading from '../loading';

import fieldFilters from '../../utils/donor-search/field-filters';
import normalizedData from '../../utils/donor-search/normalized-data';
import quickFilters from '../../utils/donor-search/quick-filters';

class DonorSearchLayoutToolbar extends React.Component {
  render() {
    const {
      apiKey,
      customFields,
      dayOptions,
      exportUrl,
      filters,
      isLoading,
      keyword,
      monthOptions,
      sort,
      url,
      yearOptions
    } = this.props;

    return (
      <div className="toolbar">
        <div id="search-form" className="d-search-form">
          <div className="d-filters">
            <DonorSearchQuickFiltersMenu
              filters={quickFilters(filters)}
              onDisable={this.props.removeField}
              onEnable={this.props.submitFilter}
              />

            <DonorSearchFieldFiltersMenu
              customFields={customFields}
              dayOptions={dayOptions}
              filters={fieldFilters(filters)}
              monthOptions={monthOptions}
              onSubmit={this.props.submitFilter}
              url={url}
              yearOptions={yearOptions}
              />

            <DonorSearchKeyword
              keyword={keyword}
              onSubmit={this.props.submitKeyword}
              url={url}
              />
          </div>

          <DonorSearchTags
            filters={filters}
            onRemove={this.props.removeField}
            />
        </div>

        <div className="download-actions" id="donors-downloads">
          <DataDownload
            apiKey={apiKey}
            dataType="donor"
            fileType="csv"
            label="Download CSV"
            params={normalizedData(filters, keyword, sort)}
            searchType="donor"
            url={exportUrl}
            />

          <DataDownload
            apiKey={apiKey}
            dataType="donor"
            fileType="xls"
            label="Download Excel"
            params={normalizedData(filters, keyword, sort)}
            searchType="donor"
            url={exportUrl}
            />
        </div>

        <button className="m-btn-fitlers btn btn__outline">Filters</button>

        <Loading
          isLoading={isLoading}
          type="bar"
        />
      </div>
    );
  }
}

export default DonorSearchLayoutToolbar;
