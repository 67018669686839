import React from 'react';
import Label from './label';

const FormItem = (props) => {
  const { children, className, label, name } = props;
  let classNames = 'form-item';

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <div className={classNames}>
      <Label label={label} htmlFor={name} />
      {children}
    </div>
  )
}

export default FormItem;
