import React from 'react';
import AutocompleteResult from './result';
import AutocompleteInput from './input';

class AutocompleteField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.value
    }

    this.remove = this.remove.bind(this);
    this.select = this.select.bind(this);
  }

  remove() {
    const { onRemove } = this.props;
    this.setState({ selected: null });

    if (onRemove) {
      onRemove();
    }
  }

  select(value) {
    const { onSelect } = this.props;
    this.setState({ selected: value });

    if (onSelect) {
      onSelect(value);
    }
  }

  render() {
    const {
      name,
      placeholder,
      searchInputName,
      selectedLabel,
      selectedValue,
      suggestionComponent,
      url
    } = this.props;

    const { selected } = this.state;

    const output = selected ?
      <AutocompleteResult
        label={selectedLabel(selected)}
        onRemove={this.remove}
      /> :
      <AutocompleteInput
        name={searchInputName}
        onSelect={this.select}
        placeholder={placeholder}
        selectedValue={selectedValue}
        suggestionComponent={suggestionComponent}
        url={url}
      />

    return (
      <>
        {output}

        <input
          type='hidden'
          name={name}
          value={selectedValue(selected)} />
      </>
    )
  }
}

export default AutocompleteField;
