import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content', 'next', 'back']

  next() {
    $(this.contentTarget).animate({ scrollLeft: `+=${this.size}` }, this.speed);
  }

  back() {
    $(this.contentTarget).animate({ scrollLeft: `-=${this.size}` }, this.speed);
  }

  get size() {
    return $(this.contentTarget).find('.activity-card').outerWidth() + 36;
  }

  get speed() {
    return 200;
  }
}
