import { Controller } from 'stimulus';
import swal from 'sweetalert';

export default class extends Controller {
  static targets = ['link']
  static values = { method: String }

  submit(event) {
    event.preventDefault();

    swal({
      title: 'Delete this item?',
      icon: 'warning',
      buttons: ['Cancel', 'Delete'],
      dangerMode: true
    })
    .then((confirmed) => {
      if (confirmed) {
        this.followLink();
      }
    });
  }

  followLink() {
    $.ajax({
      url: this.linkTarget.href,
      type: this.methodValue,
      dataType: 'script',

      beforeSend: function() {
        $('.loading').show();
      },

      success: function() {
        $('.modal').removeClass('is-active');
        $('body').removeClass('modal-open');
        $('#preview-iframe').get(0).contentWindow.location.reload();
      }
    });
  }
}