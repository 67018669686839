import formatDates from '../format-dates';

export default (value, field) => {
  if (field === 'birthdate_month_in') {
    return formatDates(value, 'MM', 'MMMM');
  }

  if (field === 'birthdate_day_in') {
    return formatDates(value, 'DD', 'D');
  }
}
