import React from 'react';
import FormItem from './form-item';
import SelectInput from './select-input';

class SelectField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      allowBlank,
      label,
      name,
      onChange,
      options,
      stateKey,
      type,
      value
    } = this.props;

    return (
      <FormItem
        className='form-select'
        label={label}
        name={name}
      >
        <div className="form-item__container">
          <SelectInput
            allowBlank={allowBlank}
            name={name}
            options={options}
            onChange={onChange.bind(this, stateKey)}
            value={value}
          />
        </div>
      </FormItem>
    )
  }
}

export default SelectField;
