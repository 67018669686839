import React from 'react';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.cancel = this.cancel.bind(this);
    this.escape = this.escape.bind(this);
  }

  componentDidMount() {
    $('body').addClass('no-scroll');
    document.addEventListener('keydown', this.escape, false);
  }

  componentWillUnmount() {
    $('body').removeClass('no-scroll');
    document.removeEventListener('keydown', this.escape, false);
  }

  update() {
    this.props.onUpdate();
  }

  cancel() {
    this.props.onCancel();
  }

  escape(event) {
    if(event.keyCode === 27) {
      this.cancel();
    }
  }

  render() {
    const { attrs, isLoading } = this.props;

    const loadingClass = isLoading ? 'btn--loading' : '';

    return (
      <div className="modal" style={{display: 'block'}} {...attrs}>
        <div className="modal-overlay" onClick={this.cancel}></div>
        <div className="modal-container">
          <header className="modal-header">
            <h3 className="modal-title title__h3">{this.props.title}</h3>
            <button onClick={this.cancel} className="modal-close">
              <svg className="icon-close ui-icon--small">
                <use xlinkHref="#icon-close"></use>
              </svg>
            </button>
          </header>

          <div className="modal-body">
            {this.props.children}
          </div>

          <footer className="modal-footer">
            <button
              className={`btn btn-green btn__small ${loadingClass}`}
              onClick={this.update}
              disabled={this.props.isDisabled}
              data-modal-submit>
                <span className="btn-label">Update</span>
                <div className="btn-icon__loading">
                  <svg className="icon-loading">
                    <use xlinkHref="#icon-loading"></use>
                  </svg>
                </div>
            </button>

            <button
              className="btn btn__neutral btn__small"
              onClick={this.cancel}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    )
  }
}

export default Modal;
