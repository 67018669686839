import React from  'react';
import AutocompleteInput from '../autocomplete/input';
import SuggestionName from '../autocomplete/suggestion/name';
import FormItem from '../form-item';

class GiftField extends React.Component {
  constructor(props) {
    super(props);
  }

  selectedValue(selected) {
    if (!selected) { return '' }

    return selected.name;
  }

  render() {
    const {
      label,
      name,
      onSelect,
      value,
      url
    } = this.props;

    return (
      <FormItem label={label}>
        <AutocompleteInput
          name={name}
          selectedValue={this.selectedValue}
          suggestionComponent={SuggestionName}
          url={url}
          value={value}
        />
      </FormItem>
    )
  }
}

export default GiftField;
