import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content'];
  static values = { url: String };

  connect() {
    $.ajax({
      url: this.urlValue,
      dataType: 'script'
    });
  }
}