import React from 'react';
import FormItem from './form-item';
import CurrencyInput from './currency-input';
import CurrencyFieldContainer from './currency-field-container';

class CurrencyField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      label,
      name,
      onChange,
      stateKey,
      type,
      value
    } = this.props;

    return (
      <FormItem
        label={label}
        className='form-item--currency'
      >
        <CurrencyFieldContainer>
          <CurrencyInput
            name={name}
            onChange={onChange.bind(this, stateKey)}
            type='text'
            value={value}
          />
        </CurrencyFieldContainer>
      </FormItem>
    )
  }
}

export default CurrencyField;
