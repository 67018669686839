import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'loading'];

  connect() {
    $(this.element).on('ajax:before', () => {
      $(this.buttonTarget).hide();
      $(this.loadingTarget).show();
    });
  }
}
