import React from 'react';
import axios from "axios";
import ProgressBar from './progress-bar';

class DonorImportUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      progress: 0,
      fileUrl: '',
      draggedOver: false,
      validFile: false
    }

    this.fileChange = this.fileChange.bind(this);
    this.dropFile = this.dropFile.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.dragLeave = this.dragLeave.bind(this);
  }

  dropFile(event) {
    event.preventDefault();
    this.setState({ draggedOver: false });

    const file = event.dataTransfer.files[0];
    this.uploadFile(file);
  }

  fileChange(event) {
    const file = event.target.files[0];
    this.uploadFile(file);
  }

  uploadFile(file) {
    if (!this.validFileType(file)) {
      this.setState({ message: 'Only CSV files are supported' });
      return;
    }

    if (!this.validFileSize(file)) {
      this.setState({ message: 'Maximum file size is 10MB' });
      return;
    }

    this.setState({
      message: null,
      validFile: true
    });

    const { awsFields, awsUrl, awsHost } = this.props;
    const formData = new FormData();

    Object.keys(awsFields).forEach((key) => {
      const value = awsFields[key];
      formData.append(key, value);
    });

    formData.append('file', file);

    axios({
      url: awsUrl,
      method: 'post',
      data: formData,

      onUploadProgress: (e) => {
        const total = Math.floor((e.loaded / e.total) * 100);
        this.setState({ progress: total });
      }
    })
    .then((response) => {
      const responseDoc = new DOMParser().parseFromString(response.data, 'application/xml');
      const key = responseDoc.getElementsByTagName('Key')[0].textContent;
      const url = `https://${awsHost}/${key}`;

      this.setState({ fileUrl: url });
      this.refs.form.submit();
    })
    .catch((error) => {
      this.setState({
        message: 'An error has occurred during the file upload.',
        validFile: false
      });
    });
  }

  validFileType(file) {
    if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
      return true;
    }

    return false;
  }

  validFileSize(file) {
    // 10MB
    if (file.size > 10000000) {
      return false;
    }

    return true;
  }

  dragOver(event) {
    event.preventDefault();
    this.setState({ draggedOver: true });
  }

  dragLeave(event) {
    event.preventDefault();
    this.setState({ draggedOver: false });
  }

  render() {
    const { message, fileUrl, draggedOver, validFile, progress } = this.state;
    const { postUrl, formToken } = this.props;
    const grey = '#bfbfbf';
    const ltBlue = '#3399cc';
    const borderColor = draggedOver ? ltBlue : grey;

    let progressBar;
    if (validFile) {
      progressBar = <ProgressBar progress={progress} />
    }

    const dropZoneStyle = {
      borderColor: borderColor
    }

    const inputStyle = {
      width: 0,
      height: 0,
      position: 'absolute',
      left: '-10000px'
    }

    return (
      <div
        className="file-dropzone-block"
        onDragOver={this.dragOver}
        onDragLeave={this.dragLeave}
        onDrop={this.dropFile}
      >
        <form
          method="post"
          action={postUrl}
          style={dropZoneStyle}
          className="file-dropzone"
          ref="form"
        >
          <label htmlFor="file" className="file-dropzone__label">
            <div className="file-dropzone__btn btn btn-green-dk mb-3">Upload CSV</div>

            <p>or drag and drop your file here</p>

            <input
              type="file"
              name="file"
              id="file"
              style={inputStyle}
              onChange={this.fileChange} />
          </label>

          {progressBar}

          <p className="file-dropzone__error form-error">{message}</p>

          <input
            type="hidden"
            name="nonprofit_donor_import[file_url]"
            value={fileUrl} />

          <input
            type="hidden"
            name="authenticity_token"
            value={formToken} />
        </form>
      </div>
    )
  }
}

export default DonorImportUpload;
