import React from  'react';
import CheckboxField from '../checkbox-field';
import { isNumberField, numberFieldValue } from '../../utils/donor-search/number-fields';

class DonorSearchQuickFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.change = this.change.bind(this);
  }

  change(stateKey, event) {
    const { onDisable, onEnable } = this.props;
    const { checked } = event.target;
    const value = this.value(stateKey);

    if (checked) {
      onEnable({ field: stateKey, value: value });
    } else {
      onDisable(stateKey);
    }
  }

  isChecked(name) {
    const { filters } = this.props;

    if (filters[name] === undefined) {
      return false;
    }

    return true;
  }

  value(field) {
    if (isNumberField(field)) {
      return numberFieldValue(field);
    }

    return true;
  }

  render() {
    return (
      <>
        <CheckboxField
          label="SYBUNT (Some Year But Unfortunately Not This Year)"
          name="is_sybunt_true"
          onChange={this.change}
          stateKey="is_sybunt_true"
          value={this.isChecked('is_sybunt_true')}
        />

        <CheckboxField
          label="LYBUNT (Last Year But Unfortunately Not This Year)"
          name="is_lybunt_true"
          onChange={this.change}
          stateKey="is_lybunt_true"
          value={this.isChecked('is_lybunt_true')}
        />

        <CheckboxField
          label="Lapsed Donors (Not in the Last 12 Months)"
          name="is_lapsed_true"
          onChange={this.change}
          stateKey="is_lapsed_true"
          value={this.isChecked('is_lapsed_true')}
        />

        <CheckboxField
          label="At-risk Donors (Not in Last 9 Months)"
          name="is_at_risk_true"
          onChange={this.change}
          stateKey="is_at_risk_true"
          value={this.isChecked('is_at_risk_true')}
        />

        <CheckboxField
          label="New Donors (Last 30 Days)"
          name="is_new_true"
          onChange={this.change}
          stateKey="is_new_true"
          value={this.isChecked('is_new_true')}
        />

        <CheckboxField
          label="Active Recurring Donors (Last 30 Days)"
          name="is_active_recurring_true"
          onChange={this.change}
          stateKey="is_active_recurring_true"
          value={this.isChecked('is_active_recurring_true')}
        />

        <CheckboxField
          label="With Email"
          name="email_present"
          onChange={this.change}
          stateKey="email_present"
          value={this.isChecked('email_present')}
        />

        <CheckboxField
          label="Without Email"
          name="email_blank"
          onChange={this.change}
          stateKey="email_blank"
          value={this.isChecked('email_blank')}
        />

        <CheckboxField
          label="With Complete Address"
          name="is_complete_address_true"
          onChange={this.change}
          stateKey="is_complete_address_true"
          value={this.isChecked('is_complete_address_true')}
        />

        <CheckboxField
          label="Without Complete Address"
          name="is_complete_address_false"
          onChange={this.change}
          stateKey="is_complete_address_false"
          value={this.isChecked('is_complete_address_false')}
        />

        <CheckboxField
          label="With Donations"
          name="donations_count_gt"
          onChange={this.change}
          stateKey="donations_count_gt"
          value={this.isChecked('donations_count_gt')}
        />

        <CheckboxField
          label="Without Donations"
          name="donations_count_eq"
          onChange={this.change}
          stateKey="donations_count_eq"
          value={this.isChecked('donations_count_eq')}
        />

        <CheckboxField
          label="With Registrations"
          name="orders_count_gt"
          onChange={this.change}
          stateKey="orders_count_gt"
          value={this.isChecked('orders_count_gt')}
        />

        <CheckboxField
          label="Without Registrations"
          name="orders_count_eq"
          onChange={this.change}
          stateKey="orders_count_eq"
          value={this.isChecked('orders_count_eq')}
        />

        <CheckboxField
          label="Year to Date Giving Greater or Equal to $500"
          name="year_to_date_total_in_cents_gteq"
          onChange={this.change}
          stateKey="year_to_date_total_in_cents_gteq"
          value={this.isChecked('year_to_date_total_in_cents_gteq')}
        />

        <CheckboxField
          label="Average Gift Greater or Equal to $500"
          name="average_total_in_cents_gteq"
          onChange={this.change}
          stateKey="average_total_in_cents_gteq"
          value={this.isChecked('average_total_in_cents_gteq')}
        />
      </>
    )
  }
}

export default DonorSearchQuickFilters;
