import React from 'react';
import SuggestionFullName from './full-name';
import SuggestionEmail from './email';

const SuggestionFullNameEmail = (props) => {
  const { suggestion } = props;

  return (
    <>
      <SuggestionFullName suggestion={suggestion} />
      <SuggestionEmail suggestion={suggestion} />
    </>
  )
}

export default SuggestionFullNameEmail;
