import React from 'react';
import DonorProfileContactInfoEmail from './email';
import DonorProfileContactInfoAddress from './address';

const DonorProfileContactInfoMain = (props) => {
  const { donor } = props;
  let phone, phoneType, alternatePhone, alternatePhoneType, newsletter, preferredName;

  if (donor.phone) {
    if (donor.phone_type) {
      phoneType = <span className="caption text--12 color--md-grey">{donor.phone_type}</span>
    }

    phone = <p className="donor-contact__phone">{donor.phone} {phoneType}</p>
  }

  if (donor.alternate_phone) {
    if (donor.alternate_phone_type) {
      alternatePhoneType = <span className="caption text--12 color--md-grey">{donor.alternate_phone_type}</span>
    }

    alternatePhone = <p className="donor-contact__phone">{donor.alternate_phone} {alternatePhoneType}</p>
  }

  if (donor.newsletter_enabled) {
    newsletter = <p className="donor-contact__newsletter">Newsletter Subscriber</p>
  }

  if (donor.preferred_name) {
    preferredName = (
      <div>
        <h3 className="label--small">Preferred Name</h3>
        <p className="donor-address__address-2">{donor.preferred_name}</p>
      </div>
    )
  }

  return (
    <div className="block-body">
      <div className="donor-email donor-block-section">
        <DonorProfileContactInfoEmail
          email={donor.email}
          emailType={donor.email_type}
        />

        <DonorProfileContactInfoEmail
          email={donor.alternate_email}
          emailType={donor.alternate_email_type}
        />

        {newsletter}
      </div>

      <div className="donor-phone donor-block-section">
        {phone}
        {alternatePhone}
      </div>

      <div className="donor-preferred-name donor-block-section">
        {preferredName}
      </div>

      <DonorProfileContactInfoAddress
        address1={donor.address_1}
        address2={donor.address_2}
        city={donor.city}
        state={donor.state}
        postal={donor.postal}
        country={donor.country}
      />
    </div>
  )
}

export default DonorProfileContactInfoMain;
