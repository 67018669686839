import React from  'react';
import AutocompleteField from '../autocomplete/field';
import SuggestionFullNameEmail from '../autocomplete/suggestion/full-name-email';
import FormItem from '../form-item';

class DonorField extends React.Component {
  constructor(props) {
    super(props);
  }

  selectedLabel(selected) {
    if (!selected) { return '' }

    return `${selected.first_name} ${selected.last_name}`;
  }

  selectedValue(selected) {
    if (!selected) { return '' }

    return selected.id;
  }

  render() {
    const {
      label,
      name,
      onRemove,
      onSelect,
      placeholder,
      searchInputName,
      value,
      url
    } = this.props;

    return (
      <FormItem label={label}>
        <AutocompleteField
          name={name}
          onRemove={onRemove}
          onSelect={onSelect}
          placeholder={placeholder}
          searchInputName={searchInputName}
          selectedValue={this.selectedValue}
          selectedLabel={this.selectedLabel}
          suggestionComponent={SuggestionFullNameEmail}
          value={value}
          url={url}
        />
      </FormItem>
    )
  }
}

export default DonorField;
