import React from 'react';
import SampleRowSet from './sample-row-set';
import Select from './select';

const Fields = (props) => {
  return props.sampleRowSets.map((sampleRowSet, index) => {
    return (
      <div key={index} className="md:grid grid-cols-2 gap-5 mb-12" data-set-index={index}>
        <div className="tw-block mb-6 md:mb-0">
          <SampleRowSet
            sampleRowSet={sampleRowSet}
            index={index}
            key={index} />
        </div>

        <div className="matching-row__col col-1-2">
          <Select
            index={index}
            onChange={props.onChange} />
        </div>
      </div>
    )
  });
}

export default Fields;
