import numeral from 'numeral';

function formatCurrency(cents, format = '$0,0.00', nullFormat = '') {
  setNullFormat(nullFormat);
  return formattedValue(cents, format);
}

function dollars(cents) {
  return cents / 100;
}

function formattedValue(cents, format) {
  return numeral(dollars(cents)).format(format);
}

function setNullFormat(nullFormat) {
  numeral.nullFormat(nullFormat);
}

export default formatCurrency;
