import React from 'react';
import DonorSearchTagRemove from './tag-remove';
import filterLabel from '../../utils/donor-search/filter-label';
import filterValue from '../../utils/donor-search/filter-value';

const DonorSearchTag = (props) => {
  const { field, onRemove, value } = props;
  const formattedField = filterLabel(field);
  const formattedValue = filterValue(value, field);

  return (
    <div
      className="filter-tag btn btn--tag"
      data-filter-tag={formattedField}>
      <span>{formattedField} {formattedValue}</span>

      <DonorSearchTagRemove
        field={field}
        onClick={onRemove}
      />
    </div>
  )
}

export default DonorSearchTag;
