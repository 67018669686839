import React from  'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DuplicateSet from './set';

class DonorDuplicatesSets extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isDisabled, onIgnore, onMerge, sets } = this.props;

    const rows = sets.map((set) => {
      return (
        <CSSTransition
          timeout={500}
          key={set.id}
          appear
        >
          <DuplicateSet
            isDisabled={isDisabled}
            onIgnore={onIgnore}
            onMerge={onMerge}
            set={set}
          />
        </CSSTransition>
      )
    });

    return (
      <TransitionGroup>
        {rows}
      </TransitionGroup>
    );
  }
}

export default DonorDuplicatesSets;
