import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import FormFieldOption from './option';

const SortableOption = SortableElement(({option, optionIndex, formFieldIndex, nestedAttributesName, allowRemove, onChange, onRemove}) => {
  return <FormFieldOption
    option={option}
    index={optionIndex}
    formFieldIndex={formFieldIndex}
    nestedAttributesName={nestedAttributesName}
    allowRemove={allowRemove}
    onChange={onChange}
    onRemove={onRemove} />
});

const SortableOptionList = SortableContainer(({options, formFieldIndex, nestedAttributesName, allowRemove, onChange, onRemove}) => {
  const optionList = options.map((option, index) => {
    return <SortableOption
      option={option}
      key={index}
      index={index}
      optionIndex={index}
      formFieldIndex={formFieldIndex}
      nestedAttributesName={nestedAttributesName}
      allowRemove={allowRemove}
      onChange={onChange}
      onRemove={onRemove} />
  });

  return <div>{optionList}</div>
});

class FormFieldOptions extends React.Component {
  constructor(props) {
    super(props);

    this.change = this.change.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.sortEnd = this.sortEnd.bind(this);
  }

  change(index, value) {
    this.props.onChange(index, value);
  }

  add(event) {
    event.preventDefault();
    this.props.onAdd();
  }

  remove(index) {
    this.props.onRemove(index);
  }

  sortEnd(data, event) {
    this.props.onSortEnd(data.oldIndex, data.newIndex);
  }

  render() {
    const { options, formFieldIndex, nestedAttributesName } = this.props;
    const allowRemove = options.length > 1;

    return (
      <div className="custom-checkbox-options">
        <SortableOptionList
          options={options}
          formFieldIndex={formFieldIndex}
          nestedAttributesName={nestedAttributesName}
          allowRemove={allowRemove}
          onChange={this.change}
          onRemove={this.remove}
          onSortEnd={this.sortEnd}
          lockAxis='y'
          transitionDuration={100}
          lockToContainerEdges={true}
          useDragHandle={true} />

        <button
          className="link--add-field"
          onClick={this.add}
          data-form-field-options-add>
          Add Option
        </button>
      </div>
    )
  }
}

export default FormFieldOptions;
