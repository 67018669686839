import React from 'react';

const DonorProfileContactInfoAddressField = (props) => {
  const { className, value } = props;

  if (!value) {
    return null;
  }

  return (
    <p className={className}>{value}</p>
  )
}

export default DonorProfileContactInfoAddressField;
