import React from 'react';

const DonorSearchKeywordClear = (props) => {
  const { keyword, url } = props;

  if (!keyword) {
    return null;
  }

  return (
    <a
      className="d-search-clear d-search-delete clear-search"
      data-clear-search="true"
      href={url}>
    </a>
  )
}

export default DonorSearchKeywordClear;
