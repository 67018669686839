import React from 'react';
import FormFieldOptions from './options';
import arrayMove from 'array-move';

class FormFieldItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.formField.field_type,
      options: this.props.formField.options
    }

    this.remove = this.remove.bind(this);
    this.setType = this.setType.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.setOptionsPosition = this.setOptionsPosition.bind(this);
  }

  remove(e) {
    e.preventDefault();

    const { index } = this.props;
    this.props.onRemove(index);
  }

  setType(event) {
    const { value } = event.target;
    const { options } = this.state;

    if (value === 'select' && !options.length) {
      this.addOption();
    }

    this.setState({
      type: value
    });
  }

  setOptions(index, value) {
    const { options } = this.state;

    options[index] = value;

    this.setState({
      options: options
    });
  }

  setOptionsPosition(oldIndex, newIndex) {
    const options = arrayMove(this.state.options, oldIndex, newIndex);

    this.setState({
      options: options
    });
  }

  addOption() {
    const { options } = this.state;
    const option = '';

    options.push(option);

    this.setState({
      options: options
    });
  }

  removeOption(index) {
    const { options } = this.state;

    options.splice(index, 1);

    this.setState({
      options: options
    });
  }

  inputName(property, index) {
    const { nestedAttributesName } = this.props;
    return `${nestedAttributesName}[${index}][${property}]`;
  }

  style() {
    let style;

    if (this.props.formField['_destroy']) {
      style = { display: 'none' }
    }

    return style;
  }

  renderLabel() {
    const { formField, index } = this.props;

    return (
      <div className="form-item form-item--text">
        <div className="form-item__container">
          <input
            type="text"
            placeholder="Question"
            className="required"
            name={this.inputName('label', index)}
            defaultValue={formField.label}
            data-form-field-label />
        </div>
      </div>
    )
  }

  renderType() {
    const { formField, index } = this.props;
    const { type } = this.state;
    const name = this.inputName('field_type', index)

    return (
      <div className="form-item form-select">
        <div className="form-item__container">
          <select
            value={type}
            onChange={this.setType}
            name={name}
            ref="formFieldType"
            data-form-field-type>
            <option value="text">Text</option>
            <option value="boolean">Checkbox</option>
            <option value="select">Drop-down</option>
          </select>
        </div>
      </div>
    )
  }

  renderRequired() {
    const { formField, index, allowRequired } = this.props;
    const name = this.inputName('required', index);

    if (allowRequired === false) {
      return (
        <input value="off" name={name} type="hidden" />
      )
    }

    return (
      <div className="form-item form-item--checkbox" data-form-field-required-wrapper>
        <label className="form-item__container" htmlFor={name}>
          <input
            value="off"
            name={name}
            type="hidden" />
          <input
            defaultChecked={formField.required}
            name={name}
            id={name}
            type="checkbox" />
          <span className="form-item__label">
            Required
          </span>
        </label>
      </div>
    )
  }

  renderOptions() {
    const { index, nestedAttributesName } = this.props;
    const { type, options } = this.state;
    if (type !== 'select') { return; }

    return <FormFieldOptions
              options={options}
              formFieldIndex={index}
              nestedAttributesName={nestedAttributesName}
              onAdd={this.addOption}
              onRemove={this.removeOption}
              onChange={this.setOptions}
              onSortEnd={this.setOptionsPosition} />
  }

  renderHidden() {
    const { formField, index } = this.props;
    const { options } = this.state;
    const nameId = this.inputName('id', index);
    const nameWeight = this.inputName('weight', index);
    const nameDestroy = this.inputName('_destroy', index);
    const nameOptions = this.inputName('options', index);
    const optionsString = JSON.stringify(options);

    return (
      <div>
        <input
          type="hidden"
          value={formField.id}
          name={nameId} />

        <input
          type="hidden"
          value={index}
          name={nameWeight} />

        <input
          type="hidden"
          defaultValue={formField['_destroy']}
          name={nameDestroy} />

        <input
          type="hidden"
          defaultValue={optionsString}
          name={nameOptions}
          data-form-field-options />
      </div>
    )
  }

  renderRemove() {
    return (
      <button
        className="multiple-fields__remove"
        onClick={this.remove}
        data-form-field-remove>
        <svg className="icon-close ui-icon--small">
          <use xlinkHref="#icon-close"></use>
        </svg>
      </button>
    )
  }

  render() {
    const style = this.style();

    return (
      <div
        className="fields"
        data-form-fields-group="true"
        style={style}>
        {this.renderLabel()}
        {this.renderType()}
        <div className="multiple-fields__actions">
          {this.renderOptions()}
          {this.renderHidden()}

          <div className="flex justify-between pt-2 md:pt-0">
            {this.renderRequired()}
            {this.renderRemove()}
          </div>
        </div>
      </div>
    )
  }
}

export default FormFieldItem;
