import React from 'react';
import DonorProfileContactInfoAddressField from './address-field';
import DonorProfileContactInfoCityStatePostal from './city-state-postal';

const DonorProfileContactInfoAddress = (props) => {
  const {
    address1,
    address2,
    city,
    state,
    postal,
    country
  } = props;

  if (!address1 && !address2 && !city && !state && !postal && !country) {
    return null;
  }

  return (
    <div className="donor-address donor-block-section">
      <h3 className="label--small">Address</h3>
      <DonorProfileContactInfoAddressField
        className='donor-address__address-1'
        value={address1}
      />

      <DonorProfileContactInfoAddressField
        className='donor-address__address-2'
        value={address2}
      />

      <DonorProfileContactInfoCityStatePostal
        city={city}
        className='donor-address__city-state-zip'
        postal={postal}
        state={state}
      />

      <DonorProfileContactInfoAddressField
        className='donor-address__country'
        value={country}
      />
    </div>
  )
}

export default DonorProfileContactInfoAddress;
