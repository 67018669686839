import React from 'react';

const Select = (props) => {
  return (
    <div className="form-select">
      <label className="mb-2">Column {props.index + 1} Field</label>

      <div className="form-item__container">
        <select
          name="nonprofit_donation_import[field_map][]"
          onChange={props.onChange}>
          <option value="">Skip Field</option>
          <option value="first_name">First Name</option>
          <option value="last_name">Last Name</option>
          <option value="address_1">Address 1</option>
          <option value="address_2">Address 2</option>
          <option value="city">City</option>
          <option value="state">State / Province</option>
          <option value="postal">Zip / Postal Code</option>
          <option value="country">Country</option>
          <option value="phone">Phone</option>
          <option value="email">Email</option>
          <option value="newsletter_enabled">Newsletter Subscriber</option>
          <option value="total">Total</option>
          <option value="frequency">Frequency</option>
          <option value="transacted_at">Transaction Date</option>
          <option value="transaction_code">Transaction ID</option>
          <option value="transaction_description">Transaction Description</option>
          <option value="subscription_code">Subscription ID</option>
          <option value="payment_method_type">Payment Method Type</option>
          <option value="payment_method_card_type">Payment Card Type</option>
          <option value="gift_name">Gift Designation</option>
          <option value="campaign_id">Campaign</option>
        </select>
      </div>
    </div>
  )
}

export default Select;
