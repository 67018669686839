import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['items'];
  static values = { weightUrl: String }

  connect() {
    $(this.itemsTarget).sortable({
      handle: '.builder-block__drag',

      update: () => {
        this.handleChange();
      }
    });
  }

  handleChange() {
    const newWeights = {};

    $('.preview__section').each(function(index) {
      const id = $(this).data('id');
      newWeights[id] = index;
    });

    $.ajax({
      url: this.weightUrlValue,
      type: 'PUT',
      dataType: 'script',
      data: JSON.stringify({ weights: newWeights }),
      contentType: 'application/json',

      beforeSend: function() {
        $('.loading').show();
      },

      success: function() {
        $('#preview-iframe').get(0).contentWindow.location.reload();
      }
    });
  }
}
