import React from 'react';

const SuggestionName = (props) => {
  const { suggestion } = props;

  return (
    <div data-suggestion-name className="autosuggest-item__name">
      {suggestion.name}
    </div>
  )
}

export default SuggestionName;
