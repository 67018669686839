import React from 'react';

const Suggestion = (props) => {
  const { isHighlighted, suggestion } = props;
  const SuggestionComponent = props.suggestionComponent;
  const className = isHighlighted ? 'is-highlighted' : null;

  return (
    <div data-suggestion={suggestion.id} className={`autosuggest-item ${className}`}>
      <SuggestionComponent suggestion={suggestion} />
    </div>
  )
}

export default Suggestion;
