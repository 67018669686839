import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() =>
  <span className='ui-sortable-handle'>
    <svg className="icon-drag" width="18px" height="18px">
      <use xlinkHref="#icon-drag"></use>
    </svg>
  </span>
);

class GiftItem extends React.Component {
  constructor(props) {
    super(props);

    this.onClickRemove = this.onClickRemove.bind(this);
  }

  onClickRemove(event) {
    event.preventDefault();
    this.props.onRemove(this.props.id);
  }

  render() {
    return (
      <div className='gift-item' data-gift-item>
        <DragHandle />

        <a
          href=""
          onClick={this.onClickRemove}
          className='gift-item--delete'
          data-gift-remove={this.props.id}>
          <svg className="icon-close ui-icon--small">
            <use xlinkHref="#icon-close"></use>
          </svg>
        </a>

        <span
          className='gift-item--label'
          data-gift-name-label>
          {this.props.name}
        </span>

        <input
          name="campaign[donation_page_attributes][gift_ids][]"
          value={this.props.id}
          type="hidden" />
      </div>
    )
  }
}

export default GiftItem;
