import React from 'react';

const Label = (props) => {
  const { htmlFor, label } = props;

  if (!label) { return null; }

  return (
    <label htmlFor={htmlFor}>
      {label}
    </label>
  )
}

export default Label;
