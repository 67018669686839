import React from 'react';

// Used so a form can be submitted by
// hitting `enter` in any form field.
const HiddenButton = () => {
  const style = { display: 'none' };

  return (
    <button type="submit" style={style}></button>
  )
}

export default HiddenButton;
