import React from 'react';
import OptionSet from './option-set';

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      allowBlank,
      name,
      onChange,
      options,
      stateKey,
      value
    } = this.props;

    return (
      <select
        id={name}
        name={name}
        onChange={onChange}
        value={value}
      >
        <OptionSet
          allowBlank={allowBlank}
          options={options}
        />
      </select>
    )
  }
}

export default SelectInput;
