import React from  'react';

class DonorSearchAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.click = this.click.bind(this);
  }

  click() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { title } = this.props;

    let fields = (
      <div className="filter-group__fields is-open">
        {this.props.children}
      </div>
    )

    fields = this.state.isOpen ? fields : null;

    return (
      <div className="filter-group">
        <div className="filter-toggle title__h4 mb-6" onClick={this.click}>
          <span>{title}</span>
          <svg className="icon-chevron-down ui-icon--small">
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </div>

        {fields}
      </div>
    )
  }
}

export default DonorSearchAccordion;
