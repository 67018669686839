import React from  'react';
import Record from './record';

class DonorDuplicatesSet extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isDisabled, onIgnore, onMerge, set } = this.props;
    const { master } = this.props.set;

    const duplicates = set.duplicates.map((record) => {
      return <Record key={record.id} record={record} />
    });

    return (
      <div className="duplicate-set mb-4" data-set={set.id}>
        <div className="card-block">
          <div className="duplicate-set__records">
            <div className="duplicate-set__title title__h2 mb-6">
              {master.first_name} {master.last_name}
            </div>

            <div className="duplicate-set__section mb-6">
              <div className="label--small mb-1">Primary</div>

              <Record record={master} />
            </div>

            <div className="duplicate-set__section">
              <div className="label--small mb-1">Possible Duplicates</div>

              {duplicates}
            </div>
          </div>

          <div className="duplicate-set__controls">
            <button
              className="btn btn__outline"
              disabled={isDisabled}
              data-merge="true"
              onClick={onMerge.bind(this, set)}
            >
              Merge
            </button>

            <button
              className="btn btn__neutral"
              disabled={isDisabled}
              data-ignore="true"
              onClick={onIgnore.bind(this, set)}
            >
              Ignore
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DonorDuplicatesSet;
