import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';

class DataDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    }

    this.click = this.click.bind(this);
    this.start = this.start.bind(this);
  }

  checkJob(jobId) {
    const { apiKey } = this.props;

    axios({
      url: `/api/nonprofits/jobs/${jobId}`,
      method: 'get',
      params: {
        api_key: apiKey
      }
    })
    .then((response) => {
      const { status } = response.data;

      if (status === 'failed') {
        this.showError();
        this.setState({ disabled: false });
        return;
      }

      const fileUrl = response.data.file_url;
      if (fileUrl) {
        this.showComplete();
        this.setState({ disabled: false });
        return window.location.href = fileUrl;
      }

      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch((error) => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  click(event) {
    event.preventDefault();

    this.start();
  }

  start() {
    const {
      dataType,
      fileType,
      params,
      searchType,
      url
    } = this.props;

    const data = Object.assign(params, {
      data_type: dataType,
      file_type: fileType,
      search_type: searchType
    });

    this.showLoading();
    this.setState({ disabled: true });

    axios({
      url: url,
      method: 'post',
      data: data
    })
    .then((response) => {
      const jobId = response.data.job_id;
      setTimeout(() => this.checkJob(jobId), 1000);
    })
    .catch(() => {
      this.showError();
      this.setState({ disabled: false });
    });
  }

  showLoading() {
    swal({
      title: 'Your file is being prepared...',
      text: 'The download will start automatically when the file is ready.',
      icon: 'info',
      buttons: false
    });
  }

  showComplete() {
    swal({
      title: 'Your file is ready.',
      icon: 'success'
    });
  }

  showError() {
    swal({
      title: 'An error occurred and your file cannot be downloaded.',
      icon: 'error'
    });
  }

  render() {
    const { fileType, label } = this.props;
    const { disabled } = this.state;

    return (
      <button
        className="download-link"
        data-download-file-type={fileType}
        disabled={disabled}
        onClick={this.click}
      >
        <svg className="icon-download">
          <use xlinkHref="#icon-download"></use>
        </svg>

        <span className="link-label">
          {label}
        </span>
      </button>
    )
  }
}

export default DataDownload;
