import urlField from './url-field';

function keys(filters) {
  return Object.keys(filters);
}

export default (filters) => {
  const params = [];

  if (!filters) {
    return params;
  }

  keys(filters).forEach((key) => {
    // Always make the filter value(s) an array
    // for easy looping down below.
    const values = [].concat(filters[key]);

    values.forEach((value) => {
      const param = urlField(key, value);
      params.push(param);
    });
  });

  return params;
}
