import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import GiftItem from './item';
import GiftInput from './input';
import arrayMove from 'array-move';

const SortableGift = SortableElement(({gift, onRemove, apiKey, onSelect, onSubmit, onChange}) =>
  <GiftItem
    id={gift.id}
    name={gift.name}
    isEditing={gift.isEditing}
    apiKey={apiKey}
    onChange={onChange}
    onSelect={onSelect}
    onSubmit={onSubmit}
    onRemove={onRemove} />
);

const SortableGiftList = SortableContainer(({gifts, onRemove, apiKey, onSelect, onSubmit}) => {
  return (
    <div className="form-item">
      {gifts.map((gift, index) => (
        <SortableGift
          key={`item-${index}`}
          index={index}
          gift={gift}
          apiKey={apiKey}
          onSelect={onSelect}
          onSubmit={onSubmit}
          onRemove={onRemove} />
      ))}
    </div>
  );
});

class GiftContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gifts: props.gifts
    }

    this.createGift = this.createGift.bind(this);
    this.addGift = this.addGift.bind(this);
    this.removeGift = this.removeGift.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  createGift(gift) {
    const url = this.props.saveGiftUrl;
    $.post(url, {
      gift: { name: gift.name }
    })
    .done((response) => {
      gift.id = response.id;
      this.addGift(gift);
    });
  }

  addGift(gift) {
    const gifts = this.state.gifts;

    gifts.push(gift);

    this.setState({
      gifts: gifts
    });
  }

  removeGift(giftId) {
    const gifts = this.state.gifts;

    const filteredGifts = gifts.filter((gift) => {
      return gift.id != giftId;
    });

    this.setState({
      gifts: filteredGifts
    });
  }

  onSortEnd(data, event) {
    const gifts = arrayMove(this.state.gifts, data.oldIndex, data.newIndex);

    this.setState({
      gifts: gifts,
    });
  }

  renderControls() {
    let controls;

    if (this.state.gifts.length >= this.props.maxGiftCount) {
      controls = (
        <div data-gift-maximum-message>
          <em>Maximum number of gift designations has been reached</em>
        </div>
      )
    } else {
      controls = (
        <GiftInput
          apiKey={this.props.apiKey}
          onSelect={this.addGift}
          onSubmit={this.createGift} />
      )
    }

    return controls;
  }

  renderLabel() {
    if (this.state.gifts.length) {
      return <label>Gift Designations (drag to set order)</label>
    }
  }

  render() {
    const label = this.renderLabel();
    const controls = this.renderControls();

    return (
      <div className="form-item">
        {label}

        <SortableGiftList
          onRemove={this.removeGift}
          gifts={this.state.gifts}
          onSortEnd={this.onSortEnd}
          apiKey={this.props.apiKey}
          onSelect={this.addGift}
          onSubmit={this.createGift}
          lockAxis='y'
          transitionDuration={100}
          lockToContainerEdges={true}
          useDragHandle={true}
        />

        {controls}
      </div>
    )
  }
}

export default GiftContainer;
