import React from  'react';
import Scrollbar from '../scrollbar';
import DonorSearchQuickFilters from './quick-filters';

class DonorSearchQuickFiltersDropDown extends React.Component {
  componentDidMount() {
    $(".d-quick-filters__drop-down").tinyscrollbar();
  }

  render() {
    const {
      filters,
      onDisable,
      onEnable
    } = this.props;

    return (
      <div className="d-quick-filters__drop-down drop-down is-open" data-quick-filters>
        <Scrollbar />

        <div className="drop-down--wrapper viewport">
          <div className="drop-down--inner-wrapper overview">
            <DonorSearchQuickFilters
              filters={filters}
              onDisable={onDisable}
              onEnable={onEnable}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default DonorSearchQuickFiltersDropDown;
