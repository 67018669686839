import isArrayField from './is-array-field';

export default (key, value) => {
  let field;

  if (isArrayField(key)) {
    field = `q[${key}][]`;
  } else {
    field = `q[${key}]`;
  }

  return { field, value };
}
