import React from 'react';
import FormItem from './form-item';

class CheckboxField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      label,
      name,
      onChange,
      stateKey,
      value
    } = this.props;

    return (
      <div className="form-item form-item--checkbox">
        <input
          type='hidden'
          value='0'
          name={name}
        />

        <label
          className="form-item__container"
          htmlFor={name}
        >
          <input
            checked={value}
            id={name}
            name={name}
            onChange={onChange.bind(this, stateKey)}
            type="checkbox"
            value="1"
          />

          <span className="form-item__label">
            {label}
          </span>
        </label>
      </div>
    )
  }
}

export default CheckboxField;
