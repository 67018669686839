import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

class FormFieldOption extends React.Component {
  constructor(props) {
    super(props);

    this.change = this.change.bind(this);
    this.remove = this.remove.bind(this);
  }

  change(event) {
    const { index } = this.props;
    const { value } = event.target;
    this.props.onChange(index, value);
  }

  remove(event) {
    event.preventDefault();
    const { index } = this.props;
    this.props.onRemove(index);
  }

  name() {
    const { formFieldIndex, index, nestedAttributesName } = this.props;
    return `${nestedAttributesName}[${formFieldIndex}][options][${index}]`;
  }

  renderSortHandle() {
    const SortHandle = SortableHandle(() =>
      <span className="sort-handle ui-sortable-handle">
        <svg className="icon-drag ui-icon--small">
          <use xlinkHref="#icon-drag"></use>
        </svg>
      </span>
    );

    return <SortHandle />;
  }

  renderRemove() {
    if (!this.props.allowRemove) {
      return;
    }

    return (
      <a
        href=""
        className="custom-checkbox-options--remove"
        onClick={this.remove}
        data-form-field-option-remove>
          <svg className="icon-close ui-icon--small">
            <use xlinkHref="#icon-close"></use>
          </svg>
      </a>
    )
  }

  render() {
    const name = this.name();
    const value = this.props.option;
    const sortHandle = this.renderSortHandle();
    const removeControl = this.renderRemove();

    return (
      <div className="custom-checkbox-options--item" data-form-field-option-wrapper>
        {sortHandle}

        <input
          type="text"
          value={value}
          className="custom-checkbox-options--input required"
          placeholder="Option"
          name={name}
          maxLength="50"
          data-form-field-option
          onChange={this.change} />

        {removeControl}
      </div>
    )
  }
}

export default FormFieldOption;
