import React from 'react';

class DonorProfileGenderAge extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { age } = this.props;

    if (!age) {
      return null;
    }

    return <p>Age {age}</p>;
  }
}

export default DonorProfileGenderAge;
