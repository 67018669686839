import swal from 'sweetalert';

function swalError(errors) {
  swal({
    title: 'An error occurred and the data was not saved. Please refresh and try again.',
    icon: 'error',
    text: `${errors}`
  });
}

export default swalError;
