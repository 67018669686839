import React from 'react';
import DonorSearchFilterDateFieldOptions from './date-field-options';
import eventValue from '../../../utils/event-value';

const DonorSearchFilterDateField = (props) => {
  const { onChange, options, type } = props;
  const id = `filter-input-${type}`;

  return (
    <div className="form-item form-select">
      <div className="form-item__container">
        <select
          id={id}
          onChange={event => onChange(eventValue(event))}
        >
          <DonorSearchFilterDateFieldOptions
            options={options}
          />
        </select>
      </div>
    </div>
  )
}

export default DonorSearchFilterDateField;
