import React from 'react';

const SampleRowSet = (props) => {
  const rows = props.sampleRowSet.map((row, index) => {
    const className = index === 0 ? 'px-4 py-2 text-sm font-medium' : 'px-4 py-2 border-t border-neutral-200 border-solid text-sm';

    return (
      <div key={index}>
        <div className={className}>
          {row}
        </div>
      </div>
    )
  });

  return (
    <>
      {rows}
    </>
  )
}

export default SampleRowSet;
