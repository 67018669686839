import React from 'react';

const SuggestionFullName = (props) => {
  const { suggestion } = props;

  return (
    <div data-suggestion-name className="autosuggest-item__name">
      {suggestion.first_name} {suggestion.last_name}
    </div>
  )
}

export default SuggestionFullName;
