import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'image',
    'name',
    'fileInput',
    'info',
    'removeCheckBox',
    'remoteInput',
    'suggestion',
    'error'
  ];

  static values = {
    url: String,
    name: String,
    suggestionUrl: String,
    suggestionName: String
  }

  connect() {
    if (this.urlValue) {
      $(this.imageTarget).attr('src', this.urlValue);
      $(this.nameTarget).html(this.nameValue);
      $(this.infoTarget).show();
      $(this.fileInputTarget).hide();
    }
  }

  inputChange(event) {
    const file = event.target.files[0];
    const errors = this.validateFile(file);

    if (errors.length) {
      $(this.errorTarget).html(errors.join(', '));
      $(this.fileInputTarget).val(null);
      return;
    }

    $(this.nameTarget).html(file.name);
    $(this.imageTarget).attr('src', URL.createObjectURL(file));
    $(this.removeCheckBoxTarget).prop('checked', false);
    $(this.infoTarget).show();
    $(this.fileInputTarget).hide();
    $(this.errorTarget).hide();

    if (this.hasRemoteInputTarget) {
      $(this.remoteInputTarget).val(null);
    }
  }

  clickRemove(event) {
    if (!event.target.checked) {
      return;
    }

    $(this.fileInputTarget).val(null);
    $(this.nameTarget).html(null);
    $(this.imageTarget).attr('src', null);
    $(this.fileInputTarget).show();
    $(this.infoTarget).hide();

    if (this.hasSuggestionTarget) {
      $(this.suggestionTarget).show();
    }
  }

  useSuggestion() {
    $(this.remoteInputTarget).val(this.suggestionUrlValue);

    $(this.fileInputTarget).val(null);
    $(this.fileInputTarget).hide();
    $(this.nameTarget).html(this.suggestionNameValue);
    $(this.imageTarget).attr('src', this.suggestionUrlValue);
    $(this.infoTarget).show();
    $(this.suggestionTarget).hide();
  }

  validateFile(file) {
    const errors = [];

    // 5MB
    if (file.size >= 5000000) {
      errors.push('File must be smaller than 5MB');
    }

    const allowed = [
      'image/jpeg',
      'image/png',
      'image/gif'
    ];

    if (allowed.indexOf(file.type) === -1) {
      errors.push('File must be .jpg, .png, or .gif');
    }

    return errors;
  }
}