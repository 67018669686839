import React from 'react';
import FormField from './form-field';
import SelectField from './select-field';

class StateField extends React.Component {
  constructor(props) {
    super(props);
  }

  label() {
    const { provinceLabel, stateLabel } = this.props;

    return this.countryHasStates() ? stateLabel : provinceLabel;
  }

  options() {
    const { provinceOptions, stateOptions } = this.props;

    return this.countryHasStates() ? stateOptions : provinceOptions;
  }

  freeForm() {
    const { freeForm } = this.props;

    if (freeForm) { return true; }

    return this.freeFormCountry();
  }

  freeFormCountry() {
    const { country } = this.props;

    return country !== 'US' && country !== 'CA';
  }

  countryHasStates() {
    const { country } = this.props;

    return country === 'US' || country === '';
  }

  render() {
    const {
      allowBlank,
      name,
      onChange,
      required,
      stateKey,
      value
    } = this.props;

    const label = this.label();
    const freeForm = this.freeForm();

    if (freeForm) {
      return (
        <FormField
          label={label}
          name={name}
          onChange={onChange}
          required={required}
          stateKey={stateKey}
          type='text'
          value={value}
        />
      )
    }

    const options = this.options();

    return (
      <SelectField
        allowBlank={allowBlank}
        label={label}
        name={name}
        onChange={onChange}
        options={options}
        required={required}
        stateKey={stateKey}
        value={value}
      />
    )
  }
}

export default StateField;
