import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['addLink', 'record', 'index'];

  connect() {
    this.renderIndexes();
    this.recordCount = this.recordTargets.length;

    $(this.element).on(`nested:fieldAdded`, () => {
      this.recordCount += 1;
      this.toggleAddLink();
      this.renderIndexes();
    });

    $(this.element).on(`nested:fieldRemoved`, (event) => {
      $(event.field).addClass('destroyed');
      this.recordCount -= 1;
      this.toggleAddLink();
      this.renderIndexes();
    });

    this.toggleAddLink();
  }

  renderIndexes() {
    // Only target the visible rows since nested_form
    // keeps deleted records but makes them "display: none".
    const visibleElements = $(this.element).find(this.indexTargets).filter(function() {
      return $(this).parents('.destroyed').length === 0;
    });

    visibleElements.each((index, element) => {
      $(element).html(index + 1);
    });
  }

  toggleAddLink() {
    if (this.recordCount > 2) {
      $(this.element).find(this.addLinkTarget).hide();
    } else {
      $(this.element).find(this.addLinkTarget).show();
    }
  }
}