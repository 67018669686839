const keys = [
  'average_total_in_cents_gteq',
  'donations_count_eq',
  'donations_count_gt',
  'email_blank',
  'email_present',
  'is_complete_address_true',
  'is_complete_address_false',
  'is_active_recurring_true',
  'is_at_risk_true',
  'is_lapsed_true',
  'is_lybunt_true',
  'is_new_true',
  'is_sybunt_true',
  'orders_count_eq',
  'orders_count_gt',
  'year_to_date_total_in_cents_gteq',
];

export default (filters) => {
  const object = {};

  keys.forEach((key) => {
    if (filters[key] === undefined) { return; }

    object[key] = filters[key];
  });

  return object;
}
