import React from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import debounce from 'debounce';
import Suggestion from './suggestion';
import Loading from '../loading';

class AutocompleteInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      suggestValue: props.value || ''
    }

    this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 300);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
  }

  getSuggestionValue(suggestion) {
    return suggestion;
  }

  onChange(event) {
    this.setState({ suggestValue: event.target.value });
  }

  onSuggestionsFetchRequested({ value }) {
    if (value.length < 1) { return; }

    const { url } = this.props;
    const params = { value: value }
    this.setState({ isLoading: true });

    return axios.get(url, { params: params })
      .then((response) => {
        this.setState({ suggestions: response.data });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      })
  }

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  onSuggestionSelected(event, { suggestionValue }) {
    const { onSelect, selectedValue } = this.props;

    if (onSelect) {
      onSelect(suggestionValue);
    }

    const formattedValue = selectedValue(suggestionValue);
    this.setState({ suggestValue: formattedValue });
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const { suggestionComponent } = this.props;

    return (
      <Suggestion
        isHighlighted={isHighlighted}
        suggestion={suggestion}
        suggestionComponent={suggestionComponent}
      />
    );
  }

  render() {
    const { isLoading, suggestValue, suggestions } = this.state;
    const { name, placeholder } = this.props;

    const inputProps = {
      name: name,
      onChange: this.onChange,
      placeholder: placeholder,
      value: suggestValue
    }

    return (
      <>
        <Autosuggest
          getSuggestionValue={this.getSuggestionValue}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          renderSuggestion={this.renderSuggestion}
          suggestions={suggestions}
        />

        <Loading isLoading={isLoading} />
      </>
    );
  }
}

export default AutocompleteInput;
