import React from 'react';
import DonorSearchTag from './tag';

const fields = (filters) => {
  return Object.keys(filters);
}

const DonorSearchTags = (props) => {
  const { filters, onRemove } = props;

  if (!filters) {
    return null;
  }

  const mapped = fields(filters).map((field, index) => {
    return (
      <DonorSearchTag
        key={index}
        field={field}
        onRemove={onRemove}
        value={filters[field]}
      />
    );
  });

  return (
    <div className="filter-tags" id="filter-tags">
      {mapped}
    </div>
  )
}

export default DonorSearchTags;
