import React from 'react';

const DonorSearchFilterFieldsSelect = (props) => {
  const {
    customFields,
    onChange,
    value
  } = props;

  const customFieldOptions = customFields.map((field) => {
    return (
      <option value={`field:${field.label}`} key={field.id}>
        {field.label}
      </option>
    )
  });

  return (
    <div className="form-item form-select">
      <div className="form-item__container">
        <select id="filters" onChange={onChange} value={value}>
          <option value="nonprofit_tags_name_cont_any">Tagged With</option>
          <option value="first_name_cont_any">First Name</option>
          <option value="last_name_cont_any">Last Name</option>
          <option value="preferred_name_cont_any">Preferred Name</option>
          <option value="address_1_cont_any">Address 1</option>
          <option value="address_2_cont_any">Address 2</option>
          <option value="city_cont_any">City</option>
          <option value="state_cont_any">State / Province</option>
          <option value="postal_cont_any">Zip / Postal Code</option>
          <option value="country_cont_any">Country</option>
          <option value="phone_cont_any">Phone</option>
          <option value="alternate_phone_cont_any">Alternate Phone</option>
          <option value="email_cont_any">Email</option>
          <option value="alternate_email_cont_any">Alternate Email</option>
          <option value="newsletter_enabled_eq">Newsletter Subscriber</option>
          <option value="birthdate_month_in">Birthday Month</option>
          <option value="birthdate_day_in">Birthday Day</option>
          <option value="birthdate_year_in">Birthday Year</option>
          <option value="job_title_cont_any">Job Title</option>
          <option value="company_cont_any">Employer</option>
          {customFieldOptions}
          <option value="year_to_date_total_in_cents_between">Year to Date Giving</option>
          <option value="average_total_in_cents_between">Average Gift</option>
          <option value="lifetime_total_in_cents_between">Lifetime Giving</option>
          <option value="transaction_in_year">Has a Transaction in Year</option>
          <option value="statement_in_year">Received a Statement for Year</option>
          <option value="statement_not_in_year">Has not Received a Statement for Year</option>
        </select>
      </div>
    </div>
  )
}

export default DonorSearchFilterFieldsSelect;
