import React from 'react';
import moment from 'moment';

class NonprofitDashboardBlogList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blogs: [],
      isLoading: false,
      isDataError: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    $.ajax({
      url: 'https://4agoodcause.com/wp-json/wp/v2/posts',
      method: 'GET',
      cache: false,
      data: {
        per_page: 5
      }
    })
      .done((data) => {
        this.setState({ blogs: data });
      })
      .fail((error) => {
        this.setState({ isDataError: true });
      })
      .always(() => {
        this.setState({ isLoading: false });
      });
  }

  renderBlogs() {
    const { blogs } = this.state;

    if (blogs.length === 0) {
      return <p className="dashboard__no-activity">No recent blogs.</p>;
    }

    return blogs.map((blog) => {
      const blogDate = moment.parseZone(blog.date).format('MMM D, YYYY');
      const blogTitle = { __html: blog.title.rendered };

      return (
        <div className="pt-4 first:pt-0" key={blog.id}>
          <h4 className="">
            <a href={blog.link} target="_blank" dangerouslySetInnerHTML={blogTitle}></a>
          </h4>
        </div>
      );
    });
  }

  renderBody() {
    if (this.state.isLoading) {
      return <div className="dashboard-loading text-sm text-neutral-600">Loading...</div>;
    }

    if (this.state.isDataError) {
      return <p className="dashboard__error">This data cannot be shown at this time.</p>;
    }

    return this.renderBlogs();
  }

  render() {
    const body = this.renderBody();

    return (
      <div className="dashboard-blog-posts dash-block p-5">
        <header className="mb-5">
          <h3 className="text-h5">
            From the 4aGC Blog
          </h3>
        </header>
        <div className="space-y-4 divide-y divide-neutral-200 divide-solid">
          {body}

          <p className="pt-6 text-sm">
            <a href="https://4agoodcause.com/blog" target="_blank" className="underline hover:no-underline">More Posts</a>
          </p>
        </div>
      </div>
    );
  }
}

export default NonprofitDashboardBlogList;
