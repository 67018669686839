import React from 'react';

class AutocompleteResult extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, onRemove } = this.props;

    return (
      <div>
        <div className="autocompete-item">
          <h3 className="title__h5" data-campaign-name="true">
            {label}
          </h3>

          <button
            type="button"
            className="btn-action-icon btn-delete-icon" data-autocomplete-remove="true"
            onClick={onRemove}>
          </button>
        </div>
      </div>
    )
  }
}

export default AutocompleteResult;
